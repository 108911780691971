define("a24-staffshift-subdomain-agency/controllers/index/agency-candidate-search-view/compliance-exemptions", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["organisation_id", "organisation_name", "site_id", "site_name", "ward_name", "at_client", "applies_to", "compliance_rule_id", "compliance_rule_name", "effective_from", "effective_fromTo", "effective_fromFrom", "effective_to", "effective_toTo", "effective_toFrom", "page", "sortBy", "vis_cols"],
        organisation_id: "",
        organisation_name: "",
        site_id: "",
        site_name: "",
        ward_name: "",
        at_client: "",
        applies_to: "",
        compliance_rule_id: "",
        compliance_rule_name: "",
        effective_from: "",
        effective_fromTo: "",
        effective_fromFrom: "",
        effective_to: "",
        effective_toTo: "",
        effective_toFrom: "",
        page: "1",
        sortBy: "effective_from::asc",
        vis_cols: "2,4,5,7,9,10,11"
    });
});