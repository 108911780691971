define("a24-staffshift-subdomain-agency/controllers/index/agency-list-shift-requests", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["shift_request_id", "site_id", "site_name", "created_by_user_id", "created_by_user_name", "created_at", "created_atFrom", "created_atTo", "status", "completed_at", "completed_atFrom", "completed_atTo", "page", "sortBy", "vis_cols"],
        shift_request_id: "",
        site_id: "",
        site_name: "",
        created_by_user_id: "",
        created_by_user_name: "",
        created_at: "",
        created_atFrom: "",
        created_atTo: "",
        status: "created|processing",
        completed_at: "",
        completed_atFrom: "",
        completed_atTo: "",
        page: "1",
        sortBy: "created_at::asc",
        vis_cols: "2,3,4,5,6"

    });
});