define("a24-staffshift-subdomain-agency/components/page-agency-assignments", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,

        // private variables
        _objGetSiteSuggestions: null,
        _objGetOrgSuggestions: null,
        _objGetContractSuggestions: null,
        _objGetJobTitleSuggestions: null,
        _objDatagridConfig: null,
        _objSelectedItem: null,
        _objOutstandingTimesheetSpecialPopupAction: null,

        _objAgencyCandidateNotificationPromise: null,
        _objAgencyUsersServicePromise: null,
        _objEntityActionPromise: null,
        _objConsultantRolePromise: null,
        _objUsersPromise: null,

        _arrSelectedItems: null,
        _arrConsultantRoles: null,
        _arrExtraActions: null,

        _bPageLoading: true,
        _bShowOutstandingTimesheetPopup: false,
        _bOutstandingTimesheetLoading: false,
        _bShowSentMessagePopup: false,
        _bShowExportPopup: false,
        _bExportPopupLoading: true,
        _bHasExportPermission: false,

        _sOutstandingTimesheetPreview: "",
        _sOutstandingTimesheetErrorMessage: null,
        _sQuery: null,

        // String services
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objCandidateProfileStrings: Ember.inject.service("a24-strings-candidate-profile"),
        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),

        // Enum services
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSAgencyEnum: Ember.inject.service("a24-enums-staffshift-agency"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),

        // Constant services
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),

        // services
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),

        // custom filter services
        _objFilterCustomHoursDifferenceService: Ember.inject.service("card-datagrid-custom-filter-hours-difference"),
        _objFilterCustomConsultantRoleService: Ember.inject.service("card-datagrid-custom-filter-consultant-role"),
        _objFilterCustomTimesheetBreakHoursService: Ember.inject.service("card-datagrid-custom-filter-timesheet-break-hours"),

        // rest services
        _objAgencySiteService: Ember.inject.service("rest-agency-site"),
        _objAgencyOrgService: Ember.inject.service("rest-agency-organisation"),
        _objAgencyContractService: Ember.inject.service("rest-agency-contract"),
        _objRestAgencyJobTitle: Ember.inject.service("rest-agency-job-title"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        _objAgencyCandidateNotificationService: Ember.inject.service("rest-agency-candidate-notification"),
        _objConsultantRoleService: Ember.inject.service("rest-agency-consultant-role"),
        _objAgencyUsersService: Ember.inject.service("rest-agency-users"),
        _objRestEntityAction: Ember.inject.service("rest-entity-action-section"),
        _objAgencyExportService: Ember.inject.service("rest-agency-export"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("assignments"));

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/agency-shift-manifest");

            this.set("_objOutstandingTimesheetSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._closeOutstandingTimesheetPopup();
                }
            });

            this.doServiceCalls();
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_bPageLoading", true);

            var objPromiseConsultantRole = this.get("_objConsultantRoleService").getConsultantRoles(this, "_objConsultantRolePromise", function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objPromiseList = {
                _objConsultantRolePromise: objPromiseConsultantRole
            };

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objConsultantRolePromise"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrConsultantRoles = objResponses._objConsultantRolePromise.objResponse;

                    if (a24Core.isEmpty(arrConsultantRoles)) {
                        arrConsultantRoles = [];
                    }
                    objThis.set("_arrConsultantRoles", arrConsultantRoles);

                    objThis.setupPageConfig(arrConsultantRoles);
                }
            }]);
        },

        _handleServerErrors: function _handleServerErrors() {},

        setupPageConfig: function setupPageConfig(arrConsultantRoles) {
            var _this3 = this;

            var arrAssignmentStatusOption = this.get("_objSSCoreEnum").getList("assignmentStatus");
            var arrTimesheetStatusOption = this.get("_objSSAgencyEnum").getList("timesheetStatus");

            var arrAssignmentStatusDropdown = [];
            for (var k = 0; k < arrAssignmentStatusOption.length; k++) {
                arrAssignmentStatusDropdown.push({
                    sTitle: arrAssignmentStatusOption[k].sLabel,
                    sValue: arrAssignmentStatusOption[k].sLabel // This is done to comply with api
                });
            }

            var arrTimesheetStatusDropdown = [];
            for (var j = 0; j < arrTimesheetStatusOption.length; j++) {
                arrTimesheetStatusDropdown.push({
                    sTitle: arrTimesheetStatusOption[j].sLabel,
                    sValue: arrTimesheetStatusOption[j].sLabel // This is done to comply with api
                });
            }

            var arrIntervalStatusesDropdown = this.get("_objSSAgencyEnum").getList("intervalStatus").map(function (objItem) {
                return {
                    sTitle: objItem.sLabel,
                    sValue: objItem.sLabel
                };
            });

            var arrBooleanDropdown = [{
                sValue: "true",
                sTitle: this.get("_objStringsService").getString("yes")
            }, {
                sValue: "false",
                sTitle: this.get("_objStringsService").getString("no")
            }];

            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
            var objConstants = this.get("_objLibConstants");
            arrQueryType.push({
                "sValue": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS,
                "sLabel": this.get("_objStringsService").getString("contains")
            });

            var arrConsultantRolesDropdown = [{
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            }];

            for (var x = 0; x < arrConsultantRoles.length; x++) {
                //For now we will equal on the name since advance search does not have access to id
                arrConsultantRolesDropdown.push({
                    sTitle: arrConsultantRoles[x].name,
                    sValue: arrConsultantRoles[x]._id
                });
            }

            var arrQueryTypeIsEmpty = [{
                sLabel: this.get("_objStringsService").getString("is"),
                sValue: objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS
            }, {
                sLabel: this.get("_objStringsService").getString("isEmpty"),
                sValue: objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY
            }];

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("orderId"),
                sProperty: "order_reference",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                bIsClickable: true,
                iWidth: 5,
                arrQueryType: [{
                    sLabel: this.get("_objStringsService").getString("is"),
                    sValue: this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS")
                }]
            }, {
                iUniqueColNum: 25,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetNumber"),
                sProperty: "timesheet_number",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                bIsClickable: true,
                iWidth: 5,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSCoreStrings").getString("candidate"),
                sProperty: "agency_shift_booked_candidate_full_name",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 10,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 26,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetCandidate"),
                sProperty: "timesheet_candidate_name",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 10,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollNumber"),
                sProperty: "agency_shift_booked_candidate_payroll_number",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 5,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 27,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetPayrollNumber"),
                sProperty: "timesheet_candidate_agency_reference",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 5,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 47,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "order_site_id",
                sFilterType: "suggest",
                bText: true,
                bFilterOr: true,
                iWidth: 5,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sSiteId",
                sFilterOptionsLabelKey: "sOrgNameSiteName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sSiteName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sSiteName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objAgencySiteService").getLinkedClients(_this3, "_objGetSiteSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sOrgNameSiteName = arrData[i].sOrganisationName + ": " + arrData[i].sSiteName;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "order_site_name",
                bText: true,
                bIsClickable: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 48,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "order_organisation_id",
                sFilterType: "suggest",
                bFilterOr: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sOrganisationId",
                sFilterOptionsLabelKey: "sOrganisationName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sOrganisationName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sOrganisationName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objAgencyOrgService").getAgencyOrganisations(_this3, "_objGetOrgSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "order_organisation_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "order_ward_name",
                sFilterType: "text",
                bIsClickable: true,
                bText: true,
                bFilterOr: true,
                iWidth: 10,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 28,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetWardName"),
                sProperty: "timesheet_ward_name",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 10,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 29,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetWardReference"),
                sProperty: "timesheet_ward_reference",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 10,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("shiftReferenceNumber"),
                sProperty: "agency_shift_reference_number",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 5,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("poNumber"),
                sProperty: "order_po_number",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 5,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetShiftReference"),
                sProperty: "time_interval_shift_reference",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 10,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 30,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetPONumber"),
                sProperty: "timesheet_po_number",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 5,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objCandidateProfileStrings").getString("jobFamily"),
                sProperty: "order_job_family_name",
                bText: true,
                bCustom: false,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 15
            }, {
                iUniqueColNum: 31,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetJobFamily"),
                sProperty: "timesheet_job_family_name",
                bText: true,
                bCustom: false,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 15
            }, {
                iUniqueColNum: 49,
                sTitle: this.get("_objCandidateProfileStrings").getString("jobTitle"),
                sProperty: "order_job_title_id",
                sFilterType: "suggest",
                bSilent: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sDisplayName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sQueryString, funcOnComplete) {
                    var objQuery = {
                        sTitleName: {
                            mValue: sQueryString,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sTitleName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objRestAgencyJobTitle").getJobTitles(_this3, "_objGetJobTitleSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            arrData[i].sDisplayName = arrData[i].sJobFamilyName + ": " + arrData[i].sTitleName;
                        }

                        // Call the callback with the formatted data
                        funcOnComplete(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcOnComplete([]);
                    }));
                }
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objCandidateProfileStrings").getString("jobTitle"),
                sProperty: "order_job_title_name",
                sFilterType: "suggest",
                bText: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 15
            }, {
                iUniqueColNum: 50,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetJobTitle"),
                sProperty: "timesheet_job_title_id",
                sFilterType: "suggest",
                bCustom: true,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sDisplayName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                iWidth: 15,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sQueryString, funcOnComplete) {
                    var objQuery = {
                        sTitleName: {
                            mValue: sQueryString,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sTitleName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objRestAgencyJobTitle").getJobTitles(_this3, "_objGetJobTitleSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            arrData[i].sDisplayName = arrData[i].sJobFamilyName + ": " + arrData[i].sTitleName;
                        }

                        // Call the callback with the formatted data
                        funcOnComplete(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcOnComplete([]);
                    }));
                }
            }, {
                iUniqueColNum: 32,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetJobTitle"),
                sProperty: "timesheet_job_title_name",
                bText: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 15
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objSSAgencyStrings").getString("assignmentStartDate"),
                sProperty: "shift_start_date",
                sFilterType: "date",
                bDate: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 8
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSAgencyStrings").getString("assignmentEndDate"),
                sProperty: "shift_end_date",
                sFilterType: "date",
                bDate: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 8
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objSSAgencyStrings").getString("assignmentBookedHours"),
                sProperty: "agency_shift_booked_minutes",
                sFilterType: "text",
                bCustom: true,
                bStopFiltering: true,
                iWidth: 5,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    return _this3._formatTime(objRowData[objHeader.sProperty]);
                }
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetBillableHours"),
                sProperty: "time_interval_total_billable_minutes",
                sFilterType: "text",
                bStopFiltering: true,
                iWidth: 10,
                bCustom: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    return _this3._formatTime(objRowData[objHeader.sProperty]);
                }
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetBreakHours"),
                sProperty: "time_interval_total_non_billable_minutes",
                iWidth: 10,
                bCustom: true,
                sFilterType: "custom",
                sFilterCustomComponentName: "card-datagrid-custom-filter-timesheet-break-hours",
                objFilterCustomService: this.get("_objFilterCustomTimesheetBreakHoursService"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    return _this3._formatTime(objRowData[objHeader.sProperty]);
                }
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetStartDate"),
                sProperty: "time_interval_start_date",
                sFilterType: "date",
                bDate: true,
                bFilterOr: true,
                bFilterAnd: true,
                iWidth: 8
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetEndDate"),
                sProperty: "time_interval_end_date",
                sFilterType: "date",
                bDate: true,
                bFilterOr: true,
                bFilterAnd: true,
                iWidth: 8
            }, {
                iUniqueColNum: 42,
                sTitle: this.get("_objSSAgencyStrings").getString("hoursDifference"),
                sProperty: "minutes_difference",
                iWidth: 10,
                bCustom: true,
                sFilterType: "custom",
                sFilterCustomComponentName: "card-datagrid-custom-filter-hours-difference",
                objFilterCustomService: this.get("_objFilterCustomHoursDifferenceService"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    return _this3._formatTime(objRowData[objHeader.sProperty]);
                }
            }, {
                iUniqueColNum: 33,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetWeekEnding"),
                sProperty: "period_end_date",
                sFilterType: "date",
                bDate: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 8
            }, {
                iUniqueColNum: 34,
                sTitle: this.get("_objSSAgencyStrings").getString("wardChange"),
                sProperty: "ward_change",
                sFilterType: "bool",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 5
            }, {
                iUniqueColNum: 35,
                sTitle: this.get("_objSSAgencyStrings").getString("jobTitleChange"),
                sProperty: "job_title_change",
                sFilterType: "bool",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 5
            }, {
                iUniqueColNum: 51,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollContract"),
                sProperty: "agency_order_contract_id",
                sFilterType: "suggest",
                bSilent: true,
                bCustom: true,
                iWidth: 10,
                bFilterAnd: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryTypeIsEmpty, true),
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sQueryString, funcOnComplete) {
                    var objQuery = {
                        name: {
                            mValue: sQueryString,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objAgencyContractService").getAgencyContracts(_this3, "_objGetContractSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcOnComplete(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcOnComplete([]);
                    }));
                }
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollContract"),
                sProperty: "agency_order_contract_name",
                bText: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 52,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetPayrollContract"),
                sProperty: "timesheet_contract_id",
                sFilterType: "suggest",
                bSilent: true,
                iWidth: 10,
                bFilterAnd: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryTypeIsEmpty, true),
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sQueryString, funcOnComplete) {
                    var objQuery = {
                        name: {
                            mValue: sQueryString,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objAgencyContractService").getAgencyContracts(_this3, "_objGetContractSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcOnComplete(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcOnComplete([]);
                    }));
                }
            }, {
                iUniqueColNum: 36,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetPayrollContract"),
                sProperty: "timesheet_contract_name",
                bText: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 37,
                sTitle: this.get("_objSSAgencyStrings").getString("contractChange"),
                sProperty: "contract_change",
                sFilterType: "bool",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 5
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objSSAgencyStrings").getString("confirmed"),
                sProperty: "time_interval_confirmed",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                iWidth: 5,
                sFilterType: "bool",
                bBool: true,
                bMapping: true,
                arrMappingData: [].concat(arrBooleanDropdown)
            }, {
                iUniqueColNum: 22,
                sTitle: this.get("_objSSAgencyStrings").getString("authorised"),
                sProperty: "time_interval_authorised",
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                iWidth: 5,
                sFilterType: "bool",
                bBool: true,
                bMapping: true,
                arrMappingData: [].concat(arrBooleanDropdown)
            }, {
                iUniqueColNum: 23,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetIntervalStatus"),
                sProperty: "time_interval_status",
                sFilterType: "dropdownMulti",
                iWidth: 5,
                bMapping: true,
                arrMappingData: arrIntervalStatusesDropdown
            }, {
                iUniqueColNum: 38,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetOverallStatus"),
                sProperty: "timesheet_status",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrTimesheetStatusDropdown,
                iWidth: 5
            }, {
                iUniqueColNum: 24,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetTotalHoursPay"),
                sProperty: "time_interval_contract_pay",
                bStopFiltering: true,
                sFilterType: "text",
                iWidth: 5,
                bCustom: true,
                sExportPreColumn: "timesheet_currency",
                sExportPreColumnName: this.get("_objSSAgencyStrings").getString("timesheetCurrency"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sTotal = objRowData[objHeader.sProperty] + "";
                    if (!a24Core.isEmpty(objRowData.timesheet_currency)) {
                        sTotal = objRowData.timesheet_currency + objRowData[objHeader.sProperty];
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sTotal + "\">" + sTotal + "</span>");
                }
            }, {
                iUniqueColNum: 39,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetTotalExpensesPay"),
                sProperty: "timesheet_contract_total_expense_pay",
                sFilterType: "text",
                bStopColumnSort: true,
                bStopFiltering: true,
                bCustom: true,
                iWidth: 5,
                sExportPreColumn: "timesheet_currency",
                sExportPreColumnName: this.get("_objSSAgencyStrings").getString("timesheetCurrency"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sTotal = objRowData[objHeader.sProperty] + "";
                    if (!a24Core.isEmpty(objRowData.timesheet_currency)) {
                        sTotal = objRowData.timesheet_currency + objRowData[objHeader.sProperty];
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sTotal + "\">" + sTotal + "</span>");
                }
            }, {
                iUniqueColNum: 40,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetTotalPay"),
                sProperty: "timesheet_contract_total_gross_pay",
                sFilterType: "text",
                bStopColumnSort: true,
                bStopFiltering: true,
                bCustom: true,
                iWidth: 5,
                sExportPreColumn: "timesheet_currency",
                sExportPreColumnName: this.get("_objSSAgencyStrings").getString("timesheetCurrency"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sTotal = objRowData[objHeader.sProperty] + "";
                    if (!a24Core.isEmpty(objRowData.timesheet_currency)) {
                        sTotal = objRowData.timesheet_currency + objRowData[objHeader.sProperty];
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sTotal + "\">" + sTotal + "</span>");
                }
            }, {
                iUniqueColNum: 41,
                sTitle: this.get("_objSSAgencyStrings").getString("timesheetTotalcharge"),
                sProperty: "timesheet_contract_total_gross_charge_incl_vat",
                sFilterType: "text",
                bStopColumnSort: true,
                bStopFiltering: true,
                bCustom: true,
                iWidth: 5,
                sExportPreColumn: "timesheet_currency",
                sExportPreColumnName: this.get("_objSSAgencyStrings").getString("timesheetCurrency"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sTotal = objRowData[objHeader.sProperty] + "";
                    if (!a24Core.isEmpty(objRowData.timesheet_currency)) {
                        sTotal = objRowData.timesheet_currency + objRowData[objHeader.sProperty];
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sTotal + "\">" + sTotal + "</span>");
                }
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objSSAgencyStrings").getString("assignmentStatus"),
                sProperty: "agency_shift_status_code",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrAssignmentStatusDropdown,
                iWidth: 5
            }, {
                iUniqueColNum: 43,
                sTitle: this.get("_objSSAgencyStrings").getString("dateFilled"),
                sProperty: "agency_shift_booked_assignment_created_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                iMinWidth: 180,
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 44,
                sTitle: this.get("_objSSAgencyStrings").getString("filledBy"),
                sProperty: "agency_shift_booked_assignment_created_user_fullname",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 45,
                sTitle: this.get("_objSSAgencyStrings").getString("filledBy"),
                sProperty: "agency_shift_booked_assignment_created_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bSilent: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sNameUserName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        items_per_page: {
                            mValue: 10
                        },
                        q: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        }
                    };

                    _this3.get("_objAgencyUsersService").getAvailableUserByQuery(_this3, "_objAgencyUsersServicePromise", _this3.get("_objNavigation.sContextId"), "", function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + ": " + arrData[i].sUsername;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }), objQuery);
                }
            }, {
                iUniqueColNum: 46,
                sTitle: this.get("_objSSAgencyStrings").getString("filledByUsername"),
                sProperty: "agency_shift_booked_assignment_created_user_username",
                bStopFiltering: true,
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 47,
                sTitle: this.get("_objCandidateProfileStrings").getString("consultantRole"),
                sProperty: "client_consultants___consultant_role_id",
                bMapping: true,
                sFilterType: "custom",
                arrConsultantRoles: arrConsultantRolesDropdown,
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                sSecondaryProperty: "consultant_id",
                sSecondaryValueProperty: "_id",
                sFilterCustomComponentName: "card-datagrid-custom-filter-consultant-role",
                objFilterCustomService: this.get("_objFilterCustomConsultantRoleService"),
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this3.get("_objAgencyUsersService").suggestUsers(_this3, "_objUsersPromise", _this3.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }];

            var objThis = this;

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("sendOutstandingTimesheetNotif"),
                sIcon: "assignment_late",
                executeCardAction: function executeCardAction() {
                    objThis._showOutstandingTimesheetPopup();
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    var bShowAction = false;
                    if (!a24Core.isEmpty(arrSelectedItems)) {
                        var objSelected = arrSelectedItems[0];
                        var objEndDate = a24DateManager.createDate(objThis.get("_objUserSession.objContextDetails.sTimezone"));
                        var objCurrentDate = a24DateManager.createDate(objThis.get("_objUserSession.objContextDetails.sTimezone"));
                        objEndDate.setDateFromRest(objSelected.time_interval_end_date);

                        if ((objSelected.time_interval_status === objThis.get("_objSSAgencyEnum.intervalStatus.NEW.backend") || objSelected.time_interval_status === objThis.get("_objSSAgencyEnum.intervalStatus.AUTHORISED.backend")) && objEndDate.isBefore(objCurrentDate)) {
                            bShowAction = true;
                        }
                    }
                    return bShowAction;
                }
            }, {
                sTitle: this.get("_objAvailabilityStrings").getString("openShiftInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllShiftsInNewWindows(objThis.get("_arrSelectedItems"));
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("openTimesheetInNewTab"),
                sIcon: "assignment",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllTimesheetsInNewWindows(objThis.get("_arrSelectedItems"));
                }
            }];

            var arrMultiItemActions = [{
                sTitle: this.get("_objAvailabilityStrings").getString("openShiftInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllShiftsInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("openTimesheetInNewTab"),
                sIcon: "assignment",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllTimesheetsInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }];

            if (!a24Core.isEmpty(arrConsultantRoles)) {
                var objQueryParams = this.get("objQueryParams");
                var objQueryParamsUpdate = {};
                var arrQueryParamsNew = [];
                var arrQueryParams = [];

                var _loop = function _loop(c) {
                    var sProp = "client_consultants_" + arrConsultantRoles[c].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[c]._id;
                    arrQueryParams.push(sProp);
                    objQueryParamsUpdate[sProp] = "";
                    objQueryParamsUpdate.queryParams = arrQueryParams;

                    // Because the backend can decide to remove/add any role at any time
                    // we wont know the exact number. So we use the id instead
                    arrQueryParamsNew.push({
                        iUniqueColNum: arrConsultantRoles[c]._id,
                        sTitle: arrConsultantRoles[c].name,
                        sProperty: sProp,
                        bCustom: true,
                        sFilterType: "text",
                        bStopFiltering: true,
                        bStopColumnSort: true,
                        iWidth: 15,
                        funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                            if (!a24Core.isEmpty(objRowData[sProp])) {
                                var sConsultants = objRowData[sProp].map(function (objConsultant) {
                                    return objConsultant.consultant_name;
                                }).join(", ");
                                return Ember.String.htmlSafe("<span title=\"" + sConsultants + "\">" + sConsultants + "</span>");
                            }
                            return "";
                        }
                    });

                    objQueryParamsUpdate = Ember.Object.create(objQueryParamsUpdate);
                };

                for (var c = 0; c < arrConsultantRoles.length; c++) {
                    _loop(c);
                }
                this.set("_arrConsultantColumns", arrQueryParams);
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrQueryParams);
                }
                arrDataGridHeaderConfig.push.apply(arrDataGridHeaderConfig, arrQueryParamsNew);

                this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(arrQueryParams, objQueryParamsUpdate, objQueryParams, false, true));
            }

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("assignments"),
                iMaxHeight: 62 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                iResultCountLimit: 251,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrMultiItemSelectActions: arrMultiItemActions,
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-assignments",
                arrHeaderConfig: arrDataGridHeaderConfig,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true
            });

            this.set("_arrExtraActions", [{
                bLoader: true
            }]);

            this.get("_objRestEntityAction").getEntityActionsForSection(this, "_objEntityActionPromise", "assignments", function (arrResponse) {
                var objShowExport = arrResponse.find(function (objEntityAction) {
                    var objExportAction = objEntityAction.actions.find(function (objAction) {
                        return objAction.action === "export";
                    });

                    if (!a24Core.isEmpty(objExportAction) && objExportAction.allowed) {
                        return true;
                    }

                    return false;
                });

                if (!a24Core.isEmpty(objShowExport)) {
                    _this3.set("_bHasExportPermission", true);
                } else {
                    _this3.set("_bHasExportPermission", false);

                    var objNoAction = {
                        "sIcon": "not_interested",
                        "sIconClass": "a24-grey-text-darken-1",
                        "sTitle": _this3.get("_objSSAgencyStrings").getString("noAction"),
                        executeCardAction: function executeCardAction() {}
                    };

                    _this3.set("_arrExtraActions", [objNoAction]);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                var objNoAction = {
                    "sIcon": "not_interested",
                    "sIconClass": "a24-grey-text-darken-1",
                    "sTitle": _this3.get("_objSSAgencyStrings").getString("noAction"),
                    executeCardAction: function executeCardAction() {}
                };

                _this3.set("_arrExtraActions", [objNoAction]);
            }));

            this.set("_bPageLoading", false);
        },

        _formatTime: function _formatTime(iMinutes) {
            if (!a24Core.isEmpty(iMinutes)) {
                var iAbsMinutes = Math.abs(iMinutes);
                var iHours = parseInt(iAbsMinutes / 60);
                var iMin = iAbsMinutes % 60;
                var sHours = "";
                var sMin = "";
                if (iHours > 0) {
                    sHours = iHours + "h";
                    if (iMin > 0) {
                        sHours += " ";
                    }
                }
                if (iMin > 0) {
                    sMin = iMin + "m";
                }
                var sTime = (iMinutes < 0 ? "-" : "") + sHours + sMin;
                return Ember.String.htmlSafe("<span title=\"" + sTime + "\">" + sTime + "</span>");
            }
            return "";
        },

        viewAllShiftsInNewWindows: function viewAllShiftsInNewWindows(arrSelectedItems) {
            var mUniqueSelectedShifts = new Map();
            $.each(arrSelectedItems, function (iKey, objAssignment) {
                mUniqueSelectedShifts.set(objAssignment.shift_id, {
                    shiftId: objAssignment.shift_id,
                    orderId: objAssignment.order_id
                });
            });

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = mUniqueSelectedShifts.values()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var objShift = _step.value;

                    this.viewShiftInNewWindow(objShift.shiftId, objShift.orderId);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        },
        viewShiftInNewWindow: function viewShiftInNewWindow(sShiftId, sOrderId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-search-shift", {}, {
                shift_id: sShiftId,
                order_id: sOrderId
            }, true);

            this.openLink(sLink);
        },

        viewOrderInNewWindow: function viewOrderInNewWindow(sOrderId) {
            var sUrl = _environment.default.sTriageBaseUrl + "/views#agency-staffing-order-details;orderId=" + sOrderId + ";cId=" + this.get("_objNavigation").get("sContextId");

            this.openLink(sUrl);
        },

        _viewClientInNewWindow: function _viewClientInNewWindow(sClientId, sType) {
            var sRoute = "";
            var objRouteParams = {};
            if (sType === this.get("_objSSAgencyEnum.nodeLevel.SITE.backend")) {
                sRoute = "agency-client-site-view";
            } else {
                sRoute = "agency-client-ward-view";
            }

            objRouteParams[sRoute] = [sClientId];
            var sLink = this.get("_objNavigation").navigateWithinContext(sRoute, objRouteParams, null, true);
            this.openLink(sLink);
        },

        viewAllTimesheetsInNewWindows: function viewAllTimesheetsInNewWindows(arrSelectedItems) {
            var _this4 = this;

            var arrUniqueSelectedTimesheets = [];
            $.each(arrSelectedItems, function (iKey, objAssignment) {
                if (!arrUniqueSelectedTimesheets.includes(objAssignment.timesheet_id)) {
                    arrUniqueSelectedTimesheets.push(objAssignment.timesheet_id);
                }
            });

            $.each(arrUniqueSelectedTimesheets, function (iKey, iTimesheetId) {
                _this4.viewTimesheetInNewWindow(iTimesheetId);
            });
        },

        viewTimesheetInNewWindow: function viewTimesheetInNewWindow(sTimesheetId) {
            var sUrl = _environment.default.sTriageBaseUrl + "/views#agency-timesheet-details;timesheetId=" + sTimesheetId + ";cId=" + this.get("_objNavigation").get("sContextId");

            this.openLink(sUrl);
        },

        openLink: function openLink(sLink) {
            window.open(sLink);
        },

        // For multi select when we need it later on
        onItemsSelect: function onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },

        _showOutstandingTimesheetPopup: function _showOutstandingTimesheetPopup() {
            this.set("_sOutstandingTimesheetErrorMessage", "");

            var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objStartDate.setDateFromRest(this.get("_objSelectedItem.time_interval_start_date"));
            var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objEndDate.setDateFromRest(this.get("_objSelectedItem.time_interval_end_date"));

            this.set("_sOutstandingTimesheetPreview", this.get("_objSSAgencyStrings").getTokenString("outstandingTimesheetPreview", objStartDate.getDateTimeFormat() + "-" + objEndDate.getTimeFormat(), this.get("_objSelectedItem.order_reference"), this.get("_objSelectedItem.timesheet_site_name"), this.get("_objSelectedItem.timesheet_number"), this.get("_objSelectedItem.timesheet_number"), this.get("_objUserSession.objContextDetails.sDisplayName")));

            this.set("_bOutstandingTimesheetLoading", false);
            this.set("_bShowOutstandingTimesheetPopup", true);
        },
        _closeOutstandingTimesheetPopup: function _closeOutstandingTimesheetPopup() {
            if (!this.get("_bOutstandingTimesheetLoading")) {
                this.set("_bShowOutstandingTimesheetPopup", false);
            }
        },
        _sendOutstandingTimesheetNotification: function _sendOutstandingTimesheetNotification() {
            var _this5 = this;

            this.set("_bOutstandingTimesheetLoading", true);

            this.get("_objAgencyCandidateNotificationService").sendShiftConfirmation(this, "_objAgencyCandidateNotificationPromise", this.get("_objUserSession").getUserContextReference().mId, this.get("_objSelectedItem.timesheet_candidate_id"), {
                type: "outstanding_timesheet",
                data: {
                    timesheet_id: this.get("_objSelectedItem.timesheet_id"),
                    time_interval_id: this.get("_objSelectedItem.time_interval_id"),
                    shift_id: this.get("_objSelectedItem.shift_id")
                }
            }, function () {
                _this5.set("_bShowOutstandingTimesheetPopup", false);
                _this5.set("_bShowSentMessagePopup", true);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                _this5.set("_bOutstandingTimesheetLoading", false);
                if (sCode === "403" || sCode === "401") {
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objEmberLibString").getString("requiredPermission"));
                } else {
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("serverError"));
                }
            }));
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this.onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "order_reference") {

                    var sOrderId = objRowData.order_id;

                    this.viewOrderInNewWindow(sOrderId);
                } else if (sColumnName === "timesheet_number") {

                    var sTimesheetId = objRowData.timesheet_id;

                    this.viewTimesheetInNewWindow(sTimesheetId);
                } else if (sColumnName === "order_ward_name") {
                    if (!a24Core.isEmpty(objRowData.order_ward_id)) {
                        this._viewClientInNewWindow(objRowData.order_ward_id, this.get("_objSSAgencyEnum.nodeLevel.WARD.backend"));
                    }
                } else if (sColumnName === "order_site_name") {
                    this._viewClientInNewWindow(objRowData.order_site_id, this.get("_objSSAgencyEnum.nodeLevel.SITE.backend"));
                }
            },
            onOutstandingTimesheetPopupClose: function onOutstandingTimesheetPopupClose() {
                this._closeOutstandingTimesheetPopup();
            },
            onOutstandingTimesheetPopupSend: function onOutstandingTimesheetPopupSend() {
                this._sendOutstandingTimesheetNotification();
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                if (!a24Core.isEmpty(arrTableData) && this.get("_bHasExportPermission")) {
                    var objThis = this;
                    // set the query
                    if (!a24Core.isEmpty(objPOSTBody)) {
                        delete objPOSTBody.items_per_page;
                        delete objPOSTBody.page;
                        delete objPOSTBody.sort;
                        if (!a24Core.isEmpty(objPOSTBody)) {
                            this.set("_sQuery", JSON.stringify(objPOSTBody));
                        }
                        this.set("_arrExtraActions", [{
                            sTitle: this.get("_objEmberLibString").getString("exportData"),
                            sIcon: "forward",
                            executeCardAction: function executeCardAction() {
                                // open the export popup
                                objThis.set("_bExportPopupLoading", true);
                                objThis.set("_bShowExportPopup", true);
                            }
                        }]);
                    }
                } else {
                    // no results
                    var objNoAction = {
                        "sIcon": "not_interested",
                        "sIconClass": "a24-grey-text-darken-1",
                        "sTitle": this.get("_objSSAgencyStrings").getString("noAction"),
                        executeCardAction: function executeCardAction() {}
                    };

                    this.set("_arrExtraActions", [objNoAction]);
                }

                var arrConsultantRoles = this.get("_arrConsultantRoles");
                for (var l = 0; l < arrTableData.length; l++) {
                    if (!a24Core.isEmpty(arrTableData[l].client_consultants)) {
                        var _loop2 = function _loop2(i) {
                            var arrData = arrTableData[l].client_consultants.filter(function (objEntry) {
                                return objEntry.consultant_role_id === arrConsultantRoles[i]._id;
                            });
                            if (!a24Core.isEmpty(arrData)) {
                                var _sProp = "client_consultants_" + arrConsultantRoles[i].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[i]._id;
                                arrTableData[l][_sProp] = arrData;
                            }
                        };

                        for (var i = 0; i < arrConsultantRoles.length; i++) {
                            _loop2(i);
                        }
                    }
                }

                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall()
            /*arrTableData,
            objUrlConstantPart,
            objFilterParamObject,
            objTableParamObject,
            iPage,
            iPerPage,
            objSuccessCallback,
            objFailure,
            objProject*/
            {
                // need to add this to support projection when it's added
                // let arrConsultantColumns = this.get("_arrConsultantColumns");
                // if (!a24Core.isEmpty(arrConsultantColumns)) {
                //     objProject.consultants = {
                //         mValue: 1,
                //         bProject: true
                //     }
                //     for (let i = 0; i < arrConsultantColumns.length; i++) {
                //         if (!a24Core.isEmpty(objProject[arrConsultantColumns[i]])) {
                //             delete objProject[arrConsultantColumns[i]];
                //         }
                //     }
                // }

                if (this.get("_bHasExportPermission")) {
                    this.set("_sQuery", null);
                    this.set("_arrExtraActions", [{
                        bLoader: true
                    }]);
                }

                if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                    this.get("onWillDoServiceCall").apply(undefined, arguments);
                }
            },
            addQueryParamsAction: function addQueryParamsAction(arrAddedParams) {
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrAddedParams);
                }
            },

            onExportPopupCancel: function onExportPopupCancel() {
                if (!this.get("_bExportPopupLoading")) {
                    this.set("_bShowExportPopup", false);
                }
            },
            onExportPopupLoadingStateChange: function onExportPopupLoadingStateChange(bLoading) {
                this.set("_bExportPopupLoading", bLoading);
            }
        }
    });
});