define("a24-staffshift-subdomain-agency/controllers/index/agency-client-org-view/compliance-exemptions", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["candidate_id", "candidate_name", "compliance_rule_id", "compliance_rule_name", "effective_from", "effective_fromFrom", "effective_fromTo", "effective_to", "effective_toFrom", "effective_toTo", "page", "sortBy", "vis_cols"],
        candidate_id: "",
        candidate_name: "",
        compliance_rule_id: "",
        compliance_rule_name: "",
        effective_from: "",
        effective_fromFrom: "",
        effective_fromTo: "",
        effective_to: "",
        effective_toFrom: "",
        effective_toTo: "",
        page: "1",
        sortBy: "candidate_name::asc",
        vis_cols: "2,5,6,7"
    });
});