define("a24-staffshift-subdomain-agency/controllers/index/agency-list-booking-requests", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["candidate_id", "site_id", "site_name", "ward_id", "ward_name", "first_shift_start_date", "first_shift_start_dateFrom", "first_shift_start_dateTo", "last_shift_start_date", "last_shift_start_dateFrom", "last_shift_start_dateTo", "status", "created_by_user_id", "created_by_user_date", "created_by_user_dateFrom", "created_by_user_dateTo", "all_shifts___start_date", "all_shifts___start_dateFrom", "all_shifts___start_dateTo", "client_consultants___consultant_role_id", "page", "sortBy", "vis_cols",
        //Non datagrid query params
        "booking_request_id", "encodedShiftBookingRequest"],

        candidate_id: "",
        site_id: "",
        ward_id: "",
        site_name: "",
        ward_name: "",
        first_shift_start_date: "",
        last_shift_start_date: "",
        status: "booking_request_created|booking_process_initiated|booking_process_retry|booking_request_failed_completely|booking_request_requires_user_intervention|booking_request_completed_with_failures",
        created_by_user_id: "",
        created_by_user_date: "",
        all_shifts__start_date: "",
        client_consultants___consultant_role_id: "",
        page: "1",
        sortBy: "created_by_user_date::asc",
        vis_cols: "1,3,5,6,7,8,9,10",
        //Non datagrid query params
        booking_request_id: "",
        encodedShiftBookingRequest: ""
    });
});