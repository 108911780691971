define("a24-staffshift-subdomain-agency/controllers/index/agency-list-booking-restriction-audit", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["candidate_id", "client_id", "tracker_type", "tracker_type_created_by_user_id", "tracker_type_first_shift_date", "tracker_type_first_shift_dateFrom", "tracker_type_first_shift_dateTo", "tracker_type_last_shift_date", "tracker_type_last_shift_dateFrom", "tracker_type_last_shift_dateTo", "offending_shifts___start_date", "offending_shifts___start_dateFrom", "offending_shifts___start_dateTo", "restriction_rule_id", "accepted_by_user_id", "accepted_date", "accepted_dateFrom", "accepted_dateTo", "client_consultants___consultant_role_id", "violation_type", "page", "sortBy", "vis_cols"],
        candidate_id: "",
        client_id: "",
        tracker_type: "",
        tracker_type_created_by_user_id: "",
        tracker_type_first_shift_date: "",
        tracker_type_last_shift_date: "",
        offending_shifts___start_date: "",
        restriction_rule_id: "",
        accepted_by_user_id: "",
        accepted_date: "dateFilterLast7Days",
        client_consultants___consultant_role_id: "",
        violation_type: "",
        page: "1",
        sortBy: "accepted_date::asc",
        vis_cols: "1,2,3,4,5,6,8,9,10,11,12"
    });
});