define("a24-staffshift-subdomain-agency/components/page-agency-compliance-contract-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _bServerError: null,
        _bLoading: false,
        _bRulesLoading: false,

        _sContractId: null,
        _sContractName: null,
        _sContractDescription: null,
        _sContractStatus: null,
        _sComplianceRulesUrlBase: null,
        _sComplianceRulesContext: null,

        _arrHeaderActions: null,
        _arrComplianceContractData: null,
        _arrLinkedRules: null,
        _arrEntityActions: null,

        _objComplianceContractServicePromise: null,
        _objComplianceRulesQueryParams: null,
        _objEntityActionPromise: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objAgencyComplianceContractService: Ember.inject.service("rest-agency-compliance-contract"),
        _objRestCandidateType: Ember.inject.service("rest-agency-candidate-type"),
        _objRestEntityAction: Ember.inject.service("rest-entity-action"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this.set("_sComplianceRulesUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objUserSession").getUserContextReference().mId + "/compliance-rule");

            this.set("_sComplianceRulesContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"));

            this._setComplianceRulesQueryObject();

            var objComplianceContractViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-compliance-contract-view"];

            if (!a24Core.isEmpty(objComplianceContractViewParams)) {
                this.set("_sContractId", objComplianceContractViewParams.contract_id);
            }

            this.set("_arrHeaderActions", [{
                bLoader: true
            }]);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._doServiceCalls();
                }
            }]);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("complianceContract"), function () {
                _this.get("_objNavigationService").navigateBack();
            }, false, "card-actions-component");

            this._doServiceCalls();
        },

        _setComplianceRulesQueryObject: function _setComplianceRulesQueryObject() {
            var _this2 = this;

            var objQueryParamSetup = Ember.Object.create({
                queryParams: ["_id", "name", "description", "position_type", "condition", "is_default", "page", "sortBy", "vis_cols"],
                _id: "Yes",
                name: "",
                description: "",
                position_type: "",
                condition: "",
                page: "1",
                sortBy: "name::asc",
                vis_cols: "1,2,4,5,6"
            });
            var objQueryParamObject = this.get("_objQueryParamBuilderService").createQueryParamObject(objQueryParamSetup.queryParams, objQueryParamSetup, null, true, true);

            Ember.run.next(function () {
                _this2.set("_objComplianceRulesQueryParams", objQueryParamObject);
                _this2.set("_bRulesLoading", false);
            });
        },

        _doServiceCalls: function _doServiceCalls() {
            var _this3 = this;

            this.set("_bLoading", true);
            this.set("_bRulesLoading", true);
            this.set("_bServerError", false);

            var objQuery = {
                entity_id: { mValue: this.get("_sContractId") }
            };

            var objPromiseEntityAction = this.get("_objRestEntityAction").getEntityActions(this, "_objEntityActionPromise", "compliance-contract", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{}));

            var objComplianceContractPromise = this.get("_objAgencyComplianceContractService").getComplianceContract(this, "_objComplianceContractServicePromise", this.get("_sContractId"), function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this3.set("_bServerError", true);
                _this3.set("_bLoading", false);
                _this3.set("_bRulesLoading", false);
            }));

            var objPromiseList = {
                _objComplianceContract: objComplianceContractPromise,
                _objEntityAction: objPromiseEntityAction
            };

            var arrRequiredResponses = ["_objEntityAction", "_objComplianceContract"];

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objComplianceContract) && !a24Core.isEmpty(objResponses._objComplianceContract.objResponse)) {

                        var objResponse = objResponses._objComplianceContract.objResponse;
                        _this3.set("_sContractName", objResponse.name);
                        _this3.set("_sContractStatus", objResponse.status);
                        _this3.set("_sContractDescription", objResponse.description);
                        _this3.set("_arrComplianceContractData", _this3._formatComplianceContract(objResponse));
                        _this3.get("_objTitleBarService").setTitleBar(_this3.get("_objSSAgencyStrings").getString("complianceContract") + " - " + objResponse.name, function () {
                            _this3.get("_objNavigationService").navigateBack();
                        }, false, "card-actions-component");

                        _this3._setComplianceRulesQueryObject();
                    } else {
                        _this3.set("_arrComplianceContractData", []);
                    }

                    if (!a24Core.isEmpty(objResponses._objEntityAction) && !a24Core.isEmpty(objResponses._objEntityAction.objResponse)) {
                        var arrResponse = objResponses._objEntityAction.objResponse;
                        _this3.set("_arrEntityActions", arrResponse);

                        if (!a24Core.isEmpty(arrResponse) && !a24Core.isEmpty(arrResponse[0])) {
                            var objUpdateAction = arrResponse[0].actions.find(function (objAction) {
                                return objAction.action === "update" && objAction.allowed;
                            });

                            var arrHeaderActions = [];
                            var sContractStatusInactive = _this3.get("_objSSAgencyEnumsService").getBackendValue("complianceContractStatus", _this3.get("_objStringsService").getString("inactive"));

                            var objActivateAction = arrResponse[0].actions.find(function (objAction) {
                                return objAction.action === "activate" && objAction.allowed;
                            });
                            var objThis = _this3;

                            if (!a24Core.isEmpty(objUpdateAction)) {

                                arrHeaderActions.push({
                                    sTitle: _this3.get("_objSSAgencyStrings").getString("editComplianceContract"),
                                    sIcon: "edit",
                                    executeCardAction: function executeCardAction() {
                                        objThis.set("_bReactPopupOpen", true);
                                        // open edit popup
                                        objThis.set("_objReactPopupPostData", {
                                            type: "trigger_route",
                                            data: {
                                                route: "agency/complianceContracts/managementPopup",
                                                data: {
                                                    _id: objThis.get("_sContractId"),
                                                    name: objThis.get("_sContractName"),
                                                    description: objThis.get("_sContractDescription")
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            if (!a24Core.isEmpty(objActivateAction) && _this3.get("_sContractStatus") === sContractStatusInactive) {
                                arrHeaderActions.push({
                                    sTitle: _this3.get("_objSSAgencyStrings").getString("activateComplianceContract"),
                                    sIcon: "assignment_turned_in",
                                    executeCardAction: function executeCardAction() {
                                        objThis.set("_bReactPopupOpen", true);
                                        objThis.set("_objReactPopupPostData", {
                                            type: "trigger_route",
                                            data: {
                                                route: "agency/complianceContract/activatePopup",
                                                data: {
                                                    _id: objThis.get("_sContractId"),
                                                    name: objThis.get("_sContractName")
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                            _this3.set("_arrHeaderActions", arrHeaderActions);
                        }
                    } else {
                        _this3.set("_arrEntityActions", []);
                    }

                    _this3.set("_bLoading", false);
                }
            }]);
        },
        _formatComplianceContract: function _formatComplianceContract(objComplianceContractData) {

            if (!a24Core.isEmpty(objComplianceContractData.rules)) {
                this.set("_arrLinkedRules", objComplianceContractData.rules);
            } else {
                this.set("_arrLinkedRules", []);
            }

            var arrItems = [];

            arrItems.push({
                sLabel: this.get("_objStringsService").getString("name"),
                sValue: objComplianceContractData.name,
                bText: true
            });

            arrItems.push({
                sLabel: this.get("_objStringsService").getString("description"),
                sValue: objComplianceContractData.description,
                bText: true
            });

            arrItems.push({
                sLabel: this.get("_objStringsService").getString("status"),
                sValue: this.get("_objSSAgencyEnumsService").getFrontendValue("complianceContractStatus", objComplianceContractData.status),
                bText: true
            });

            return [{
                arrItems: arrItems
            }];
        },
        _onLinkUnlinkComplianceRuleTrigger: function _onLinkUnlinkComplianceRuleTrigger() {
            this.set("_bRulesLoading", true);
            this._doServiceCalls();
        },
        actions: {
            onLinkUnlinkComplianceRule: function onLinkUnlinkComplianceRule() {
                this._onLinkUnlinkComplianceRuleTrigger();
            },
            onRecieveDataReactPopup: function onRecieveDataReactPopup(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                } else if (objData.type === "refresh") {
                    this._doServiceCalls();
                }
            }
        }
    });
});