define("a24-staffshift-subdomain-agency/components/page-agency-list-shifts", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _environment, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        objQueryParams: null,
        bDatagridLoading: false,
        bClientView: false,

        // private variables
        _iProcessedCount: 0,

        _sIFrameUrl: "",

        _bShowCancelPopup: false,
        _bShowCancelConfirmPopup: false,
        _bCancelPopupLoading: false,
        _bReactPopupOpen: false,
        _bReactStaffingPopupOpen: false,
        _bReactPopupLoading: false,
        _bReactStaffingPopupLoading: false,
        _bPageLoading: true,
        _bShiftsDifferentStatusesPopup: false,

        _objGetSiteSuggestions: null,
        _objGetOrgSuggestions: null,
        _objGetJobTitleSuggestions: null,
        _objDatagridConfig: null,
        _objSelectedItem: null,
        _objReactPopupPostData: null,
        _objReactStaffingPopupPostData: null,
        _objClient: null,

        _objEntityActionShiftPromise: null,
        _objShiftStatusReasonsPromise: null,
        _objShiftStatusChangePromise: null,
        _objUsersPromise: null,
        _objConsultantRolePromise: null,
        _objAgencyUsersServicePromise: null,
        _objGetCitySuggestions: null,
        _objGetCountySuggestions: null,
        _objGetComplianceContract: null,
        _objRestAgencyClientPromise: null,

        _arrSelectedItems: null,
        _arrSelectedShifts: null,
        _arrStatusReasons: null,
        _arrEntityActions: null,
        _arrFailedCancelShifts: null,
        _arrConsultantRoles: null,
        _objGetContractSuggestions: null,

        // String services
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),

        // Enum services
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),

        // Constant services
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),
        _objAgencyConstants: Ember.inject.service("a24-constants-staffshift-agency"),

        // Services
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),

        // custom filter services
        _objFilterCustomConsultantRoleService: Ember.inject.service("card-datagrid-custom-filter-consultant-role"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),
        // rest services
        _objAgencySiteService: Ember.inject.service("rest-agency-site"),
        _objAgencyOrgService: Ember.inject.service("rest-agency-organisation"),
        _objRestAgencyJobTitle: Ember.inject.service("rest-agency-job-title"),
        _objRestEntityActionShift: Ember.inject.service("rest-entity-action-shift"),
        _objRestPermissionedActions: Ember.inject.service("rest-permissioned-actions"),
        _objRestShiftStatusReasons: Ember.inject.service("rest-shift-status-reasons"),
        _objRestShiftStatusChange: Ember.inject.service("rest-shift-status-change"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        _objAgencyUsersService: Ember.inject.service("rest-agency-users"),
        _objAgencyAutocompleteService: Ember.inject.service("rest-agency-autocomplete"),
        _objConsultantRoleService: Ember.inject.service("rest-agency-consultant-role"),
        _objUsersService: Ember.inject.service("rest-agency-users"),
        _objComplianceContractService: Ember.inject.service("rest-agency-compliance-contract"),
        _objCandidateSearchService: Ember.inject.service("rest-agency-global-search"),
        _objCandidateAddressService: Ember.inject.service("rest-agency-candidate-address-details"),
        _objAgencyContractService: Ember.inject.service("rest-agency-contract"),
        _objRestAgencyClient: Ember.inject.service("rest-agency-advanced-search-client"),
        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),

        _objFabButtonService: Ember.inject.service("fab-button"),

        _bHideOrgFilter: Ember.computed("bClientView", function () {
            if (this.get("bClientView") && ("index.agency-client-org-view" in this.get("_objNavigation.objRouteParams") || "index.agency-client-site-view" in this.get("_objNavigation.objRouteParams") || "index.agency-client-ward-view" in this.get("_objNavigation.objRouteParams"))) {
                return true;
            }
            return false;
        }),
        _bHideSiteFilter: Ember.computed("bClientView", function () {
            if (this.get("bClientView") && ("index.agency-client-site-view" in this.get("_objNavigation.objRouteParams") || "index.agency-client-ward-view" in this.get("_objNavigation.objRouteParams"))) {
                return true;
            }
            return false;
        }),
        _bHideWardFilter: Ember.computed("bClientView", function () {
            if (this.get("bClientView") && "index.agency-client-ward-view" in this.get("_objNavigation.objRouteParams")) {
                return true;
            }
            return false;
        }),
        _sContactAgencyGroupEmail: Ember.computed("objRegistrationContent", "objRegistrationContent.email_contact", function () {
            if (!a24Core.isEmpty(this.get("objRegistrationContent.email_contact"))) {
                return this.get("objRegistrationContent.email_contact");
            }
            return this.get("_objTriageStringsService").getString("staffshiftContactEmail");
        }),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("searchShifts"));

            this.set("_objCancelSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupCancelClose();
                }
            });

            // set the end from date to now here by default
            var sDefaultEndDate = this._getDefaultEndDateFilter();
            if (a24Core.isEmpty(this.get("objQueryParams.end_date.sValue"))) {
                this.set("objQueryParams.end_dateFrom.sValue", sDefaultEndDate);
            }

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.shift_id")) && !a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.order_id"))) {
                this.set("_bReactStaffingPopupOpen", true);
                this.set("_objReactStaffingPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "staffing/shift/viewPopup",
                        data: {
                            shiftId: this.get("_objNavigation.objRouteQueryParams.shift_id"),
                            orderId: this.get("_objNavigation.objRouteQueryParams.order_id")
                        }
                    }
                });
            }

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/agency-shift");
            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this.set("objChildConfig", {
                "objChildren.sReason": function objChildrenSReason(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this.doServiceCalls();
        },
        _setFabButton: function _setFabButton() {
            var _this2 = this;

            var arrPermissionedActions = this.get("_arrBookingRequestPermissionedAction");

            var bAllowAction = false;
            if (!a24Core.isEmpty(arrPermissionedActions)) {
                var objPermissionedAction = arrPermissionedActions.find(function (objPermissionedActionSection) {
                    return objPermissionedActionSection.entity_type === "booking-request";
                });
                var arrAllowedActions = [];
                if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                    objPermissionedAction.actions.forEach(function (objItem) {
                        if (objItem.allowed) {
                            arrAllowedActions.push(objItem.action);
                        }
                    });
                }
                bAllowAction = arrAllowedActions.includes("create");
            }

            if (bAllowAction) {
                var sPunchClockSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("punch_clock_white");

                this.get("_objFabButtonService").showFabButton(function () {
                    _this2.set("_bReactStaffingPopupOpen", true);
                    _this2.set("_objReactStaffingPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directBookingWizard",
                            data: {}
                        }
                    });
                }, null, this.get("_objSSAgencyStrings").getString("addBookingRequest"), null, null, null, null, sPunchClockSvg);
            }
        },

        _getDefaultEndDateFilter: function _getDefaultEndDateFilter() {
            var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objNowDate.setDateFromRestAllowTimeAlter(momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));

            return objNowDate.getDateTimeFormat();
        },

        doServiceCalls: function doServiceCalls() {
            var _this3 = this;

            this.set("_bPageLoading", true);

            a24RestUrlConstruct.setBaseUrl("/v1/system/workforce");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sWorkforceEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objWorkforcePromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sWorkforceEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 7,
                    cacheTimeUnit: 'days'
                },
                objParams: {
                    country_code: this.get("_objUserSession.objContextDetails.sCountryId") === "710" ? "ZA" : "GB"
                }
            });

            var objPromiseConsultantRole = this.get("_objConsultantRoleService").getConsultantRoles(this, "_objConsultantRolePromise", function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this3._handleServerErrors();
            }));

            var objBookingRequestPermissionedActionsPromise = this.get("_objRestPermissionedActions").getPermissionedActionsForSection(this, "_objGetPermissionedActions", "booking-request", function () {
                // do nothing since rsvp will handle it
            }, a24RestCallHelper.createJsonAjaxFailureObject(["401", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this3.set("_arrBookingRequestPermissionedAction", []);
            }));

            var objPromiseList = {
                _objConsultantRolePromise: objPromiseConsultantRole,
                _objRSVPBookingRequestPermissionedAction: objBookingRequestPermissionedActionsPromise,
                _objWorkforcePromise: objWorkforcePromise
            };

            var arrRequiredResponses = ["_objConsultantRolePromise", "_objWorkforcePromise"];

            if (this.get("bClientView")) {
                var objQuery = {};
                if ("index.agency-client-org-view" in this.get("_objNavigation.objRouteParams")) {
                    objQuery.organisation_id = {
                        mValue: this.get("_objNavigation.objRouteParams")["index.agency-client-org-view"].organisation_id
                    };
                    objQuery.node_level = {
                        bEncode: true,
                        mValue: this.get("_objStringsService").getString("organisation").toLowerCase()
                    };
                } else if ("index.agency-client-site-view" in this.get("_objNavigation.objRouteParams")) {
                    objQuery.site_id = {
                        mValue: this.get("_objNavigation.objRouteParams")["index.agency-client-site-view"].site_id
                    };
                    objQuery.node_level = {
                        bEncode: true,
                        mValue: this.get("_objSSAgencyStrings").getString("site").toLowerCase()
                    };
                } else if ("index.agency-client-ward-view" in this.get("_objNavigation.objRouteParams")) {
                    objQuery.ward_id = {
                        mValue: this.get("_objNavigation.objRouteParams")["index.agency-client-ward-view"].ward_id
                    };
                    objQuery.node_level = {
                        bEncode: true,
                        mValue: this.get("_objSSAgencyStrings").getString("ward").toLowerCase()
                    };
                }

                var objPromiseAgencyClients = this.get("_objRestAgencyClient").getClients(this, "_objRestAgencyClientPromise", objQuery, null, 1, 25, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this3._handleServerErrors();
                }));

                objPromiseList._objRSVPAgencyClients = objPromiseAgencyClients;
                arrRequiredResponses.push("_objRSVPAgencyClients");
            }

            if (a24Core.isEmpty(this.get("_arrStatusReasons"))) {
                var objShiftStatusReasons = this.get("_objRestShiftStatusReasons").getShiftsStatusReasons(this, "_objShiftStatusReasonsPromise", this.get("_objSSAgencyStrings").getString("cancelled"), function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this3._handleServerErrors();
                }));

                objPromiseList._objShiftStatusReasonsPromise = objShiftStatusReasons;

                arrRequiredResponses.push("_objShiftStatusReasonsPromise");
            }

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var bContextSupportWorkforce = false;
                    var arrConsultantRoles = objResponses._objConsultantRolePromise.objResponse;
                    var arrShiftStatusReasons = objResponses._objShiftStatusReasonsPromise.objResponse;
                    var arrWorkforce = objResponses._objWorkforcePromise.objResponse;

                    if (a24Core.isEmpty(arrConsultantRoles)) {
                        arrConsultantRoles = [];
                    }

                    if (a24Core.isEmpty(arrShiftStatusReasons)) {
                        arrShiftStatusReasons = [];
                    }

                    if (!a24Core.isEmpty(arrWorkforce)) {
                        bContextSupportWorkforce = true;
                    }
                    objThis.set("_arrConsultantRoles", arrConsultantRoles);

                    if (objThis.get("bClientView")) {
                        var arrClient = objResponses._objRSVPAgencyClients.objResponse;
                        if (!a24Core.isEmpty(arrClient)) {
                            objThis.set("_objClient", arrClient[0]);
                        }
                    }

                    objThis._setupPageConfig(arrConsultantRoles, arrShiftStatusReasons, bContextSupportWorkforce);
                    objThis._setDatagridActions();
                }
            }, {
                arrRequiredResponses: ["_objRSVPBookingRequestPermissionedAction"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrBookingRequestPermissionedAction = objResponses._objRSVPBookingRequestPermissionedAction.objResponse;

                    if (a24Core.isEmpty(arrBookingRequestPermissionedAction)) {
                        arrBookingRequestPermissionedAction = [];
                    }

                    objThis.set("_arrBookingRequestPermissionedAction", arrBookingRequestPermissionedAction);

                    objThis._setFabButton();
                }
            }]);
        },

        _handleServerErrors: function _handleServerErrors() {
            this.set("_arrStatusReasons", []);
        },

        _setStatusReasons: function _setStatusReasons(arrStatusReasons) {
            // Map the dropdown list with status reasons
            var arrStatusReasonsDropdown = [];
            if (!a24Core.isEmpty(arrStatusReasons)) {
                for (var i = 0; i < arrStatusReasons.length; i++) {
                    var objStatusReasonDropDownItem = {
                        sValue: arrStatusReasons[i],
                        sLabel: arrStatusReasons[i]
                    };
                    arrStatusReasonsDropdown.push(objStatusReasonDropDownItem);
                }
            }
            this.set("_arrStatusReasons", arrStatusReasonsDropdown);
        },

        _setupPageConfig: function _setupPageConfig(arrConsultantRoles, arrShiftStatusReasons, bContextSupportWorkforce) {
            var _this4 = this;

            this._setStatusReasons(arrShiftStatusReasons);

            var arrAssignmentStatusOption = this.get("_objSSCoreEnum").getList("assignmentStatus");
            var arrOrderStatusDropdown = [];
            for (var k = 0; k < arrAssignmentStatusOption.length; k++) {
                arrOrderStatusDropdown.push({
                    sTitle: arrAssignmentStatusOption[k].sLabel,
                    sValue: arrAssignmentStatusOption[k].sLabel // This is done to comply with api
                });
            }

            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
            var objConstants = this.get("_objConstantsLib");
            arrQueryType.push({
                "sValue": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS,
                "sLabel": this.get("_objStringsService").getString("contains")
            });

            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");
            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";

            var arrConsultantRolesDropdown = [{
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            }];
            for (var x = 0; x < arrConsultantRoles.length; x++) {
                //For now we will equal on the name since advance search does not have access to id
                arrConsultantRolesDropdown.push({
                    sTitle: arrConsultantRoles[x].name,
                    sValue: arrConsultantRoles[x]._id
                });
            }

            var arrQueryTypeIsEmpty = [{
                sLabel: this.get("_objStringsService").getString("is"),
                sValue: objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS
            }, {
                sLabel: this.get("_objStringsService").getString("isEmpty"),
                sValue: objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY
            }];

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("orderReference"),
                sProperty: "order_reference",
                sFilterType: "text",
                bText: true,
                bIsClickable: true,
                iWidth: 5,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsCandidateProfile").getString("startDate"),
                sProperty: "start_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                bIsClickable: true,
                iWidth: 15,
                bCustom: true,
                sTableCustomComponent: "card-datagrid-table-cell-custom-start-date"
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsCandidateProfile").getString("endDate"),
                sProperty: "end_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                bDate: true,
                iWidth: 15,
                iMinWidth: 180
            }, {
                bStopFiltering: this.get("_bHideOrgFilter"),
                bSilent: this.get("_bHideOrgFilter"),
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 15,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sOrganisationId",
                sFilterOptionsLabelKey: "sOrganisationName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.organisation_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.organisation_name + "\">" + objRowData.organisation_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sOrganisationName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sOrganisationName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objAgencyOrgService").getAgencyOrganisations(_this4, "_objGetOrgSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                bStopFiltering: this.get("_bHideSiteFilter"),
                bSilent: this.get("_bHideSiteFilter"),
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                sFilterType: "suggest",
                bIsClickable: true,
                bCustom: true,
                iWidth: 15,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sSiteId",
                sFilterOptionsLabelKey: "sOrgNameSiteName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.site_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.site_name + "\">" + objRowData.site_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sSiteName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sSiteName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objAgencySiteService").getLinkedClients(_this4, "_objGetSiteSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sOrgNameSiteName = arrData[i].sOrganisationName + ": " + arrData[i].sSiteName;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                bStopFiltering: this.get("_bHideWardFilter"),
                bSilent: this.get("_bHideWardFilter"),
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_name",
                sFilterType: "text",
                bIsClickable: true,
                bText: true,
                bFilterOr: true,
                iWidth: 15,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objStringsCandidateProfile").getString("jobFamily"),
                sProperty: "job_family_id",
                sFilterType: "suggest",
                bCustom: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 15,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.job_family_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.job_family_name + "\">" + objRowData.job_family_name + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objStringsCandidateProfile").getString("jobTitle"),
                sProperty: "job_title_id",
                sFilterType: "suggest",
                bCustom: true,
                bStopColumnSort: true,
                bFilterAnd: true,
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filterEditor/jobTitle",
                iWidth: 15,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.job_title_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.job_title_name + "\">" + objRowData.job_title_name + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status_code",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrOrderStatusDropdown,
                iWidth: 5
            }].concat(_toConsumableArray(bContextSupportWorkforce ? [{
                iUniqueColNum: 29,
                sTitle: this.get("_objSSAgencyStrings").getString("workforce"),
                sProperty: "workforce_name",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bStopFiltering: true,
                bStopColumnSort: true
            }] : []), [{
                iUniqueColNum: 10,
                sTitle: this.get("_objStringsService").getLocalisedString("province", sCountryCode),
                sProperty: "county",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
                // NOTE: We are reverting this to a text filter due to garbage data
                // but in the future we will change this back to a suggest thus its
                // commented out and not removed
                /*
                sFilterOptionsValueKey: "name",
                sFilterOptionsLabelKey: "name",
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: (sSuggestVal, funcCallback) => {
                    var objQuery = {
                        search_term: {
                             mValue: sSuggestVal
                        },
                        size: {
                            mValue: 10
                        }
                    };
                     this.get("_objAgencyAutocompleteService").getCounties(
                        this,
                        "_objGetCountySuggestions",
                        objQuery,
                        (arrData) => {
                            // If the data is empty, ensure it's an empty array
                            if (a24Core.isEmpty(arrData)) {
                                 arrData = [];
                            }
                             let arrReformatted = [];
                            if (!a24Core.isEmpty(arrData["results"])) {
                                arrData["results"].forEach(function(sValue) {
                                    let objCounty = {
                                        name: sValue
                                    };
                                    arrReformatted.pushObject(objCounty);
                                });
                            }
                             // Call the callback with the formatted data
                            funcCallback(arrReformatted);
                        },
                        a24RestCallHelper.createJsonAjaxFailureObject(
                            ["*", "*CODE", "*MALFORMED"],
                            (sCode, sStatus, objErrorThrown, objError) => {
                                // Call the callback with empty
                                funcCallback([]);
                            }
                        )
                    );
                }
                */
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSAgencyStrings").getString("city"),
                sProperty: "city",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
                // NOTE: We are reverting this to a text filter due to garbage data
                // but in the future we will change this back to a suggest thus its
                // commented out and not removed
                /*
                sFilterOptionsValueKey: "name",
                sFilterOptionsLabelKey: "name",
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: (sSuggestVal, funcCallback) => {
                    var objQuery = {
                        search_term: {
                             mValue: sSuggestVal
                        },
                        size: {
                            mValue: 10
                        },
                    };
                     this.get("_objAgencyAutocompleteService").getCities(
                        this,
                        "_objGetCitySuggestions",
                        objQuery,
                        (arrData) => {
                            // If the data is empty, ensure it's an empty array
                            if (a24Core.isEmpty(arrData)) {
                                 arrData = [];
                            }
                             let arrReformatted = [];
                            if (!a24Core.isEmpty(arrData["results"])) {
                                arrData["results"].forEach(function(sValue) {
                                    let objCity = {
                                        name: sValue
                                    };
                                    arrReformatted.pushObject(objCity);
                                });
                            }
                             // Call the callback with the formatted data
                            funcCallback(arrReformatted);
                        },
                        a24RestCallHelper.createJsonAjaxFailureObject(
                            ["*", "*CODE", "*MALFORMED"],
                            (sCode, sStatus, objErrorThrown, objError) => {
                                // Call the callback with empty
                                funcCallback([]);
                            }
                        )
                    );
                }
                */
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objStringsService").getLocalisedString("postCode", sCountryCode),
                sProperty: "post_code",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objSSCoreStrings").getString("candidate"),
                sProperty: "candidate_full_name",
                sFilterType: "text",
                bText: true,
                iWidth: 20,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true),
                bIsClickable: true
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objStringsService").getString("dateCreated"),
                sProperty: "date_created",
                sFilterType: "date",
                bFilterAllowTime: true,
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollNumber"),
                sProperty: "payroll_number",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objSSAgencyStrings").getString("shiftReferenceNumber"),
                sProperty: "shift_reference_number",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objSSAgencyStrings").getString("poNumber"),
                sProperty: "po_number",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 28,
                sTitle: this.get("_objStringsService").getString("description"),
                sProperty: "position_description",
                sFilterType: "text",
                bText: true,
                iWidth: 30,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("dateFilled"),
                sProperty: "booked_assignment_created_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                iMinWidth: 180,
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objSSAgencyStrings").getString("filledBy"),
                sProperty: "booked_assignment_created_user_fullname",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStrings").getString("filledBy"),
                sProperty: "booked_assignment_created_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bSilent: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sNameUserName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        items_per_page: {
                            mValue: 10
                        },
                        q: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        }
                    };

                    _this4.get("_objAgencyUsersService").getAvailableUserByQuery(_this4, "_objAgencyUsersServicePromise", _this4.get("_objNavigation.sContextId"), "", function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + ": " + arrData[i].sUsername;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }), objQuery);
                }
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objSSAgencyStrings").getString("filledByUsername"),
                sProperty: "booked_assignment_created_user_username",
                bStopFiltering: true,
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 22,
                sTitle: this.get("_objStringsCandidateProfile").getString("consultantRole"),
                sProperty: "client_consultants___consultant_role_id",
                bMapping: true,
                sFilterType: "custom",
                arrConsultantRoles: arrConsultantRolesDropdown,
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                sSecondaryProperty: "consultant_id",
                sSecondaryValueProperty: "_id",
                sFilterCustomComponentName: "card-datagrid-custom-filter-consultant-role",
                objFilterCustomService: this.get("_objFilterCustomConsultantRoleService"),
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this4.get("_objUsersService").suggestUsers(_this4, "_objUsersPromise", _this4.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 23,
                sTitle: this.get("_objSSAgencyStrings").getString("complianceContract"),
                sProperty: "agency_shift_compliance_contract_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        name: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "name"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objComplianceContractService").getComplianceContracts(_this4, "_objGetComplianceContract", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 24,
                sTitle: this.get("_objStringsCandidateProfile").getString("distance"),
                sProperty: "site_geo_location",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 20,
                bHideEmptyCheck: true,
                bCandidateSearch: true,
                sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-distance",
                objFilterCustomService: this.get("_objFilterCustomCandidateDistanceService"),
                objCandidateSearchService: this.get("_objCandidateSearchService"),
                objCandidateAddressService: this.get("_objCandidateAddressService")
            }, {
                iUniqueColNum: 25,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollContract"),
                sProperty: "contract_name",
                bText: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 26,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollContract"),
                sProperty: "contract_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryTypeIsEmpty, true),
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sQueryString, funcOnComplete) {
                    var objQuery = {
                        name: {
                            mValue: sQueryString,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objAgencyContractService").getAgencyContracts(_this4, "_objGetContractSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcOnComplete(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcOnComplete([]);
                    }));
                }
            }, {
                iUniqueColNum: 27,
                sTitle: "Candidate Proposed",
                sProperty: "has_proposed_candidate",
                sFilterType: "bool",
                bBool: true,
                bMapping: true,
                bSilent: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }]
            }]).filter(Boolean);

            var objThis = this;

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewShift"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    _this4.set("_bReactStaffingPopupOpen", true);
                    _this4.set("_objReactStaffingPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/shift/viewPopup",
                            data: {
                                shiftId: objThis.get("_arrSelectedItems")[0].shift_id,
                                orderId: objThis.get("_arrSelectedItems")[0].order_id
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("possibleCandidates"),
                sIcon: "person_outline",
                executeCardAction: function executeCardAction() {
                    var arrSelectedItems = objThis.get("_arrSelectedItems");
                    objThis._openPossibleCandidates(arrSelectedItems);
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return objThis._showPossibleCandidatesAction(arrSelectedItems);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("previouslyBooked"),
                sIcon: "assignment_return",
                executeCardAction: function executeCardAction() {
                    objThis.get("_objNavigation").navigateWithinContext("previously-booked", null, {
                        sOrderId: objThis.get("_arrSelectedItems")[0].order_id
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return objThis._showPreviouslyBookedAction(arrSelectedItems);
                }
            }, {
                bLoader: true
            }];
            var arrMultiItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("possibleCandidates"),
                sIcon: "person_outline",
                executeCardAction: function executeCardAction() {
                    var arrSelectedItems = objThis.get("_arrSelectedItems");
                    objThis._openPossibleCandidates(arrSelectedItems);
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return objThis._showPossibleCandidatesAction(arrSelectedItems);
                }
            }, {
                bLoader: true
            }];

            this.set("_arrSingleLoadingActions", arrSingleItemActions);
            this.set("_arrMultiLoadingActions", arrMultiItemActions);

            if (!a24Core.isEmpty(arrConsultantRoles)) {
                var objQueryParams = this.get("objQueryParams");
                var objQueryParamsUpdate = {};
                var arrQueryParamsNew = [];
                var arrQueryParams = [];

                var _loop = function _loop(c) {
                    var sProp = "client_consultants_" + arrConsultantRoles[c].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[c]._id;
                    arrQueryParams.push(sProp);
                    objQueryParamsUpdate[sProp] = "";
                    objQueryParamsUpdate.queryParams = arrQueryParams;

                    // Because the backend can decide to remove/add any role at any time
                    // we wont know the exact number. So we use the id instead
                    arrQueryParamsNew.push({
                        iUniqueColNum: arrConsultantRoles[c]._id,
                        sTitle: arrConsultantRoles[c].name,
                        sProperty: sProp,
                        bCustom: true,
                        sFilterType: "text",
                        bStopFiltering: true,
                        bStopColumnSort: true,
                        iWidth: 15,
                        funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                            if (!a24Core.isEmpty(objRowData[sProp])) {
                                var sConsultants = objRowData[sProp].map(function (objConsultant) {
                                    return objConsultant.consultant_name;
                                }).join(", ");
                                return Ember.String.htmlSafe("<span title=\"" + sConsultants + "\">" + sConsultants + "</span>");
                            }
                            return "";
                        }
                    });

                    objQueryParamsUpdate = Ember.Object.create(objQueryParamsUpdate);
                };

                for (var c = 0; c < arrConsultantRoles.length; c++) {
                    _loop(c);
                }
                if (this.get("bClientView")) {
                    // Remove the default filter for client view as it is not needed
                    objQueryParams.has_proposed_candidate = {
                        sDefault: "",
                        sValue: ""
                    };
                }
                this.set("_arrConsultantColumns", arrQueryParams);
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrQueryParams);
                }
                arrDataGridHeaderConfig.push.apply(arrDataGridHeaderConfig, arrQueryParamsNew);

                var objQueryParamsExisting = this.get("objQueryParams");
                if (bContextSupportWorkforce) {
                    objQueryParamsUpdate["vis_cols"] = objQueryParamsExisting.vis_cols.sValue + ",29";
                    objQueryParamsUpdate.queryParams.push("vis_cols");
                }

                this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(arrQueryParams, objQueryParamsUpdate, objQueryParams, false, true));
            }

            var objPermaQuery = {};
            var sDatagridHeader = this.get("_objSSAgencyStrings").getString("searchShifts");
            if (this.get("bClientView")) {
                var objClient = this.get("_objClient");
                sDatagridHeader = this.get("_objSSAgencyStrings").getString("shiftFor") + " ";
                if ("index.agency-client-org-view" in this.get("_objNavigation.objRouteParams")) {
                    objPermaQuery.organisation_id = {
                        mValue: this.get("_objNavigation.objRouteParams")["index.agency-client-org-view"].organisation_id
                    };
                    sDatagridHeader += objClient.organisation_name;
                } else if ("index.agency-client-site-view" in this.get("_objNavigation.objRouteParams")) {
                    objPermaQuery.site_id = {
                        mValue: this.get("_objNavigation.objRouteParams")["index.agency-client-site-view"].site_id
                    };
                    sDatagridHeader += objClient.site_name;
                } else if ("index.agency-client-ward-view" in this.get("_objNavigation.objRouteParams")) {
                    objPermaQuery.ward_id = {
                        mValue: this.get("_objNavigation.objRouteParams")["index.agency-client-ward-view"].ward_id
                    };
                    sDatagridHeader += objClient.ward_name;
                }
            }

            this.set("_objDatagridConfig", {
                sDatagridHeader: sDatagridHeader,
                iMaxHeight: 62 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                bHasReloadButton: true,
                iResultCountLimit: 251,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrMultiItemSelectActions: arrMultiItemActions,
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-search-shifts",
                arrHeaderConfig: arrDataGridHeaderConfig,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                objPermanentQuery: objPermaQuery
            });
            this.set("_bPageLoading", false);
        },

        _showPreviouslyBookedAction: function _showPreviouslyBookedAction(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems)) {
                var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objEndDate.setDateFromRest(arrSelectedItems[0].end_date);
                var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objNowDate.setDateFromRestAllowTimeAlter(momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));

                return !(arrSelectedItems[0].status_code === this.get("_objSSCoreEnum.assignmentStatus.CANCELLED.backend") || arrSelectedItems[0].status_code === this.get("_objSSCoreEnum.assignmentStatus.CLOSED.backend") || arrSelectedItems[0].status_code === this.get("_objSSCoreEnum.assignmentStatus.ACCEPTED.backend") || objEndDate.isBefore(objNowDate) || a24Core.isEmpty(arrSelectedItems[0].job_title_id) || a24Core.isEmpty(arrSelectedItems[0].job_family_id));
            }

            return false;
        },
        _showPossibleCandidatesAction: function _showPossibleCandidatesAction(arrSelectedItems) {
            var objThis = this;
            var sOrderId = arrSelectedItems[0].order_id;
            var bAllMatch = true;
            var bMeetsCriteria = true;
            // check if all same order and all meet critera
            for (var i = 0; i < arrSelectedItems.length; i++) {

                if (arrSelectedItems[i]["order_id"] !== sOrderId) {
                    bAllMatch = false;
                    break;
                }

                var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objEndDate.setDateFromRest(arrSelectedItems[i].end_date);
                var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objNowDate.setDateFromRestAllowTimeAlter(momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));

                if (arrSelectedItems[i].status_code === objThis.get("_objSSCoreEnum.assignmentStatus.CANCELLED.backend") || arrSelectedItems[i].status_code === objThis.get("_objSSCoreEnum.assignmentStatus.CLOSED.backend") || arrSelectedItems[i].status_code === objThis.get("_objSSCoreEnum.assignmentStatus.ACCEPTED.backend") || objEndDate.isBefore(objNowDate) || a24Core.isEmpty(arrSelectedItems[i].job_title_id) || a24Core.isEmpty(arrSelectedItems[i].job_family_id)) {

                    bMeetsCriteria = false;
                    break;
                }
            }

            return bAllMatch && bMeetsCriteria;
        },

        _getClientIdFromSelected: function _getClientIdFromSelected() {
            var arrSelectedItems = this.get("_arrSelectedItems");
            for (var i = 0; i < arrSelectedItems.length; i++) {
                if (!a24Core.isEmpty(arrSelectedItems[i].ward_id)) {
                    return arrSelectedItems[i].ward_id;
                }
                return arrSelectedItems[i].site_id;
            }
        },

        _getShiftDetailsFromSelected: function _getShiftDetailsFromSelected() {
            var arrSelectedItems = this.get("_arrSelectedItems");
            var arrShifts = [];
            for (var i = 0; i < arrSelectedItems.length; i++) {
                arrShifts.push({
                    shiftId: arrSelectedItems[i].shift_id,
                    orderId: arrSelectedItems[i].order_id,
                    shiftStartDate: arrSelectedItems[i].start_date,
                    shiftEndDate: arrSelectedItems[i].end_date
                });
            }

            return arrShifts;
        },

        _checkMatchingShiftData: function _checkMatchingShiftData(arrItems) {
            var arrClientIds = [];
            var arrJobTitles = [];
            var bOverlappingShifts = false;
            var objDateA = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objDateB = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            var arrSortedItems = arrItems.toArray().sort(function (objA, objB) {
                objDateA.setDateFromRest(objA.start_date);
                objDateB.setDateFromRest(objB.start_date);

                if (objDateA.getDateFormat() === objDateB.getDateFormat()) {
                    objDateA.setDateFromRest(objA.end_date);
                    objDateA.setDateFromRest(objB.end_date);
                }

                if (objDateA.isBefore(objDateB)) {
                    return -1;
                }
                if (objDateA.isAfter(objDateB)) {
                    return 1;
                }
                return 0;
            });

            var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objPreviousStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objPreviousEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            for (var i = 0; i < arrSortedItems.length; i++) {
                if (!a24Core.isEmpty(arrSortedItems[i].ward_id)) {
                    arrClientIds.push(arrSortedItems[i].ward_id);
                } else {
                    arrClientIds.push(arrSortedItems[i].site_id);
                }
                arrJobTitles.push(arrSortedItems[i].job_title_id);

                objStartDate.setDateFromRest(arrSortedItems[i].start_date);
                objEndDate.setDateFromRest(arrSortedItems[i].end_date);
                if (i === 0) {
                    objPreviousStartDate.setDateFromRest(arrSortedItems[i].start_date);
                    objPreviousEndDate.setDateFromRest(arrSortedItems[i].end_date);
                    continue;
                }

                if (objStartDate.isBefore(objPreviousEndDate)) {
                    bOverlappingShifts = true;
                }

                objPreviousStartDate.setDateFromRest(arrSortedItems[i].start_date);
                objPreviousEndDate.setDateFromRest(arrSortedItems[i].end_date);
            }

            var bIsUniqueClient = arrClientIds.filter(function (sValue, iIndex, arrSelf) {
                return arrSelf.indexOf(sValue) === iIndex;
            }).length > 1 ? false : true;
            var bIsUniqueJobTitle = arrJobTitles.filter(function (sValue, iIndex, arrSelf) {
                return arrSelf.indexOf(sValue) === iIndex;
            }).length > 1 ? false : true;

            return bIsUniqueClient && bIsUniqueJobTitle && !bOverlappingShifts;
        },

        _isFillShiftsAllowed: function _isFillShiftsAllowed(arrSelectedItems, arrPermissionedActions) {
            if (a24Core.isEmpty(arrPermissionedActions)) {
                return false;
            }
            var sAssignmentNew = this.get("_objSSCoreEnum").getBackendValue("assignmentStatus", this.get("_objSSAgencyStrings").getString("new"));
            var sAssignmentReopened = this.get("_objSSCoreEnum").getBackendValue("assignmentStatus", this.get("_objSSAgencyStrings").getString("reopened"));
            var arrShiftStatuses = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = arrSelectedItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var objItem = _step.value;

                    arrShiftStatuses.push(objItem.status_code);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var arrNotAllowsStatuses = arrShiftStatuses.filter(function (sValue) {
                return sValue !== sAssignmentNew && sValue !== sAssignmentReopened;
            });

            if (arrNotAllowsStatuses.length) {
                return false;
            }

            var objPermissionedAction = arrPermissionedActions.find(function (objPermissionedActionSection) {
                return objPermissionedActionSection.entity_type === "booking-request";
            });
            var bAllowAction = false;
            var arrAllowedActions = [];
            if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                objPermissionedAction.actions.forEach(function (objItem) {
                    if (objItem.allowed) {
                        arrAllowedActions.push(objItem.action);
                    }
                });
            }
            bAllowAction = arrAllowedActions.includes("create");

            var bSameShifts = this._checkMatchingShiftData(arrSelectedItems);

            return bAllowAction && bSameShifts;
        },

        _setDatagridActions: function _setDatagridActions() {
            var _this5 = this;

            var objThis = this;
            var arrEntityActions = this.get("_arrEntityActions");
            var arrPermissionedActions = this.get("_arrBookingRequestPermissionedAction");
            var sPunchClockSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("punch_clock");

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewShift"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    _this5.set("_bReactStaffingPopupOpen", true);
                    _this5.set("_objReactStaffingPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/shift/viewPopup",
                            data: {
                                shiftId: objThis.get("_arrSelectedItems")[0].shift_id,
                                orderId: objThis.get("_arrSelectedItems")[0].order_id
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("possibleCandidates"),
                sIcon: "person_outline",
                executeCardAction: function executeCardAction() {
                    var arrSelectedItems = objThis.get("_arrSelectedItems");
                    objThis._openPossibleCandidates(arrSelectedItems);
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return objThis._showPossibleCandidatesAction(arrSelectedItems);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("previouslyBooked"),
                sIcon: "assignment_return",
                executeCardAction: function executeCardAction() {
                    objThis.get("_objNavigation").navigateWithinContext("previously-booked", null, {
                        sOrderId: objThis.get("_arrSelectedItems")[0].order_id
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return objThis._showPreviouslyBookedAction(arrSelectedItems);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("createBookingRequest"),
                sIconSvg: sPunchClockSvg,
                executeCardAction: function executeCardAction() {
                    var objSelectedItem = _this5.get("_arrSelectedItems")[0];
                    _this5.set("_bReactStaffingPopupOpen", true);
                    var bIsWard = !a24Core.isEmpty(objSelectedItem.ward_id);
                    var objClientDetails = {
                        siteName: objSelectedItem.site_name,
                        orgName: objSelectedItem.organisation_name,
                        clientType: bIsWard ? _this5.get("_objSSAgencyEnumsService.nodeLevel.WARD.backend") : _this5.get("_objSSAgencyEnumsService.nodeLevel.SITE.backend"),
                        wardName: bIsWard ? objSelectedItem.ward_name : undefined
                    };
                    _this5.set("_objReactStaffingPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directShiftBookingWizard",
                            data: {
                                clientId: _this5._getClientIdFromSelected(),
                                clientDetails: objClientDetails,
                                shiftDetails: _this5._getShiftDetailsFromSelected(),
                                positionDetails: {
                                    jobFamily: objSelectedItem.job_family_name,
                                    jobFamilyId: objSelectedItem.job_family_id,
                                    jobTitle: objSelectedItem.job_title_name,
                                    jobTitleId: objSelectedItem.job_title_id
                                }
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this5._isFillShiftsAllowed(arrSelectedItems, arrPermissionedActions);
                }
            }];
            var arrMultiItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("possibleCandidates"),
                sIcon: "person_outline",
                executeCardAction: function executeCardAction() {
                    var arrSelectedItems = objThis.get("_arrSelectedItems");
                    objThis._openPossibleCandidates(arrSelectedItems);
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return objThis._showPossibleCandidatesAction(arrSelectedItems);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("createBookingRequests"),
                sIconSvg: sPunchClockSvg,
                executeCardAction: function executeCardAction() {
                    var objSelectedItem = _this5.get("_arrSelectedItems")[0];
                    _this5.set("_bReactStaffingPopupOpen", true);
                    var bIsWard = !a24Core.isEmpty(objSelectedItem.ward_id);
                    var objClientDetails = {
                        siteName: objSelectedItem.site_name,
                        orgName: objSelectedItem.organisation_name,
                        clientType: bIsWard ? _this5.get("_objSSAgencyEnumsService.nodeLevel.WARD.backend") : _this5.get("_objSSAgencyEnumsService.nodeLevel.SITE.backend"),
                        wardName: bIsWard ? objSelectedItem.ward_name : undefined
                    };
                    _this5.set("_objReactStaffingPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directShiftBookingWizard",
                            data: {
                                clientId: _this5._getClientIdFromSelected(),
                                clientDetails: objClientDetails,
                                shiftDetails: _this5._getShiftDetailsFromSelected(),
                                positionDetails: {
                                    jobFamily: objSelectedItem.job_family_name,
                                    jobFamilyId: objSelectedItem.job_family_id,
                                    jobTitle: objSelectedItem.job_title_name,
                                    jobTitleId: objSelectedItem.job_title_id
                                }
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this5._isFillShiftsAllowed(arrSelectedItems, arrPermissionedActions);
                }
            }];
            if (!a24Core.isEmpty(arrEntityActions)) {
                arrSingleItemActions.push({
                    sTitle: this.get("_objSSAgencyStrings").getString("cancelShift"),
                    sIcon: "assignment",
                    executeCardAction: function executeCardAction() {
                        objThis._cancelShifts(objThis.get("_arrSelectedItems"));
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var bShow = false;
                        var sAssignmentAccepted = objThis.get("_objSSCoreEnum").getBackendValue("assignmentStatus", objThis.get("_objSSAgencyStrings").getString("accepted"));

                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = arrSelectedItems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var objItem = _step2.value;

                                bShow = arrEntityActions.find(function (objEntityAction) {
                                    var objCancelAction = objEntityAction.actions.find(function (objAction) {
                                        return objAction.action === "Cancel";
                                    });

                                    if (!a24Core.isEmpty(objCancelAction)) {
                                        if (objEntityAction.entity_id === objItem.shift_id && objCancelAction.allowed) {
                                            return true;
                                        } else if (objEntityAction.entity_id === objItem.shift_id && objItem.status_code === sAssignmentAccepted && objCancelAction.reason_code === objThis.get("_objAgencyConstants").STATUS_DISALLOWS_ACTION) {

                                            return true;
                                        }
                                    }

                                    return false;
                                });
                                if (!bShow) {
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }

                        return bShow;
                    }
                });

                arrMultiItemActions.push({
                    sTitle: this.get("_objSSAgencyStrings").getString("cancelShift"),
                    sIcon: "assignment",
                    executeCardAction: function executeCardAction() {
                        objThis._cancelShifts(objThis.get("_arrSelectedItems"));
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var bShow = false;
                        var sAssignmentAccepted = objThis.get("_objSSCoreEnum").getBackendValue("assignmentStatus", objThis.get("_objSSAgencyStrings").getString("accepted"));

                        var _iteratorNormalCompletion3 = true;
                        var _didIteratorError3 = false;
                        var _iteratorError3 = undefined;

                        try {
                            for (var _iterator3 = arrSelectedItems[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                                var objItem = _step3.value;

                                bShow = arrEntityActions.find(function (objEntityAction) {
                                    var objCancelAction = objEntityAction.actions.find(function (objAction) {
                                        return objAction.action === "Cancel";
                                    });

                                    if (!a24Core.isEmpty(objCancelAction)) {
                                        if (objEntityAction.entity_id === objItem.shift_id && objCancelAction.allowed) {
                                            return true;
                                        } else if (objEntityAction.entity_id === objItem.shift_id && objItem.status_code === sAssignmentAccepted && objCancelAction.reason_code === objThis.get("_objAgencyConstants").STATUS_DISALLOWS_ACTION) {

                                            return true;
                                        }
                                    }

                                    return false;
                                });
                                if (!bShow) {
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError3 = true;
                            _iteratorError3 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                                    _iterator3.return();
                                }
                            } finally {
                                if (_didIteratorError3) {
                                    throw _iteratorError3;
                                }
                            }
                        }

                        return bShow;
                    }
                });
            }

            this.set("_arrSingleItemActions", arrSingleItemActions);
            this.set("_arrMultiItemActions", arrMultiItemActions);
            this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleItemActions);
            this.set("_objDatagridConfig.arrMultiItemSelectActions", arrMultiItemActions);
            // This is a hacky fix for a issue where we override the selected actions array because of the loader.
            // TODO: Issue to go and investigate how to fix this properly https://github.com/A24Group/A24EmberLib/issues/621
            // So the current issue is because the actions array is changed for both the single and multi it will fire
            // both observers in the datagrid container for them and set the visable actions array on the datagrid.
            // So it will show the multi select actions even though we only selected one item. Below hack will force the
            // selected item code to fire again and then show the correct actions array. Lucky for us the selected item
            // code is in a run once so even though we flip the selected to false then true it will group them and because
            // the actual selected flag did not actually change it will not fire a rerender but the code will still cause
            // selected actions to be correct
            if (!a24Core.isEmpty(this.get("_arrSelectedItems"))) {
                this.get("_arrSelectedItems").setEach("bSelected", false);
                this.get("_arrSelectedItems").setEach("bSelected", true);
            }
        },

        _cancelShifts: function _cancelShifts(arrSelectedShifts) {
            this.set("_arrSelectedShifts", arrSelectedShifts);
            // check if more than one type of status selected, and if all new or reopened
            var bNewReopended = true;
            var sAssignmentNew = this.get("_objSSCoreEnum").getBackendValue("assignmentStatus", this.get("_objSSAgencyStrings").getString("new"));
            var sAssignmentReopened = this.get("_objSSCoreEnum").getBackendValue("assignmentStatus", this.get("_objSSAgencyStrings").getString("reopened"));
            var sAssignmentAccepted = this.get("_objSSCoreEnum").getBackendValue("assignmentStatus", this.get("_objSSAgencyStrings").getString("accepted"));
            var sAssignmentCancelled = this.get("_objSSCoreEnum").getBackendValue("assignmentStatus", this.get("_objSSAgencyStrings").getString("cancelled"));

            if (!a24Core.isEmpty(arrSelectedShifts)) {
                var bAccepted = true;
                var bCancelled = true;
                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                    for (var _iterator4 = arrSelectedShifts[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                        var objItem = _step4.value;

                        if (objItem.status_code !== sAssignmentNew && objItem.status_code !== sAssignmentReopened) {
                            bNewReopended = false;
                        }
                        if (objItem.status_code !== sAssignmentAccepted) {
                            bAccepted = false;
                        }
                        if (objItem.status_code !== sAssignmentCancelled) {
                            bCancelled = false;
                        }
                    }
                } catch (err) {
                    _didIteratorError4 = true;
                    _iteratorError4 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion4 && _iterator4.return) {
                            _iterator4.return();
                        }
                    } finally {
                        if (_didIteratorError4) {
                            throw _iteratorError4;
                        }
                    }
                }

                if (!bNewReopended && !bAccepted && !bCancelled) {
                    // open popup
                    this.set("_bShiftsDifferentStatusesPopup", true);
                } else if (bAccepted) {
                    // open react popup
                    this.set("_bReactPopupOpen", true);
                    this.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/cancelShift/cancelShiftPopup",
                            data: {
                                shifts: arrSelectedShifts
                            }
                        }
                    });
                } else {

                    this.set("_bShowCancelPopup", true);
                    this.clearAllErrorMessages();
                    this.clearForm();
                }
            }
        },

        _popupCancelClose: function _popupCancelClose() {
            this.set("_bShowCancelPopup", false);
            this.set("_bCancelPopupLoading", false);
        },

        _popupCancelConfirm: function _popupCancelConfirm() {
            this.set("_bCancelPopupLoading", true);
            this.set("_bShowCancelConfirmPopup", false);

            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
            var arrSelectedShifts = this.get("_arrSelectedShifts");
            var objPayload = {
                "sAgencyId": this.get("_objNavigation.sContextId"),
                "sStatusCode": "Cancelled",
                "sStatusReason": objData.sReason
            };

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("cancelingShifts"));

            this.set("_arrFailedCancelShifts", []);

            for (var i = 0; i < arrSelectedShifts.length; i++) {
                this._cancelShift(arrSelectedShifts[i], objPayload);
            }
        },

        _cancelShift: function _cancelShift(objShift, objPayload) {
            var objThis = this;
            var funcSuccess = function funcSuccess() {
                objThis.set("_iProcessedCount", objThis.get("_iProcessedCount") + 1);
                objThis._handleResponse();
            };
            var funcFailure = function funcFailure(sCode) {
                var arrFailed = objThis.get("_arrFailedCancelShifts");
                objThis.set("_iProcessedCount", objThis.get("_iProcessedCount") + 1);

                var sReason = "";

                if (sCode === "NOT_IN_SET") {
                    sReason = objThis.get("_objSSAgencyStrings").getString("notValidReasonCancelShift");
                } else if (sCode === "ILLEGAL_TO_STATE") {
                    sReason = objThis.get("_objSSAgencyStrings").getString("shiftStatusPreventCancel");
                } else {
                    sReason = objThis.get("_objSSAgencyStrings").getString("somethingWentWrong");
                }

                arrFailed.push({
                    order_reference: objShift.order_reference,
                    reason: sReason
                });

                objThis.set("_arrFailedCancelShifts", arrFailed);

                objThis._handleResponse();
            };

            this.get("_objRestShiftStatusChange").updateShiftStatusChange(this, "_objShiftStatusChangePromise" + objShift.shift_id, objShift.order_id, objShift.shift_id, objPayload, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], funcFailure));
        },

        _refreshShiftsList: function _refreshShiftsList() {
            var _this6 = this;

            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this6.set("_bPageLoading", false);
                });
            });
        },

        _handleResponse: function _handleResponse() {
            if (this.get("_arrSelectedShifts").length === this.get("_iProcessedCount")) {
                this.set("_bCancelPopupLoading", false);
                this.set("_bShowCancelPopup", false);
                this.set("_iProcessedCount", 0);
                if (this.get("_arrFailedCancelShifts").length === 0) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("successCancelShifts"));
                    if (this.get("_bReactStaffingPopupOpen")) {
                        var objPostbody = {
                            type: "refreshShiftDetails"
                        };
                        this.set("_objReactStaffingPopupPostData", objPostbody);
                    }
                    this._refreshShiftsList();
                } else {
                    this._showFailedPopup();
                }
            }
        },

        _showFailedPopup: function _showFailedPopup() {
            this.set("_bShowFailedPopup", true);
        },

        _doEntityActionCalls: function _doEntityActionCalls(arrShiftIds) {
            var _this7 = this;

            if (a24Core.isEmpty(arrShiftIds)) {
                this._setDatagridActions();
            } else {
                this.set("_objDatagridConfig.arrSingleItemSelectActions", Ember.copy(this.get("_arrSingleLoadingActions"), true));
                this.set("_objDatagridConfig.arrMultiItemSelectActions", Ember.copy(this.get("_arrMultiLoadingActions"), true));
                var objQuery = {
                    shift_ids: { mValue: arrShiftIds.join(",") }
                };
                this.get("_objRestEntityActionShift").getShiftsEntityActions(this, "_objEntityActionShiftPromise", objQuery, function (arrResponse) {
                    _this7.set("_arrEntityActions", arrResponse);
                    _this7._setDatagridActions();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this7.set("_arrEntityActions", []);
                    _this7._setDatagridActions();
                }));
            }
        },

        _viewOrderInNewWindow: function _viewOrderInNewWindow(arrSelectedItems) {
            var _this8 = this;

            var arrUniqueSelectedOrders = [];
            $.each(arrSelectedItems, function (iKey, objOrder) {
                if (!arrUniqueSelectedOrders.includes(objOrder.order_id)) {
                    arrUniqueSelectedOrders.push(objOrder.order_id);
                }
            });

            $.each(arrUniqueSelectedOrders, function (iKey, iOrderId) {
                _this8.viewOrderInNewWindow(iOrderId);
            });
        },

        _viewClientInNewWindow: function _viewClientInNewWindow(sClientId, sType) {
            var sRoute = "";
            var objRouteParams = {};
            if (sType === this.get("_objSSAgencyEnumsService.nodeLevel.SITE.backend")) {
                sRoute = "agency-client-site-view";
            } else {
                sRoute = "agency-client-ward-view";
            }

            objRouteParams[sRoute] = [sClientId];
            var sLink = this.get("_objNavigation").navigateWithinContext(sRoute, objRouteParams, null, true);
            this.openLink(sLink);
        },
        _viewCandidateInNewWindow: function _viewCandidateInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId, "calendar"]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        viewOrderInNewWindow: function viewOrderInNewWindow(sOrderId) {
            this.openLink(this.get("_objNavigation").navigateToGWTPage("agency-staffing-order-details;orderId=" + sOrderId, true, true));
        },

        openLink: function openLink(sLink) {
            window.open(sLink);
        },


        _viewOrder: function _viewOrder(sOrderId) {
            this.get("_objNavigation").navigateToGWTPage("agency-staffing-order-details;orderId=" + sOrderId, true);
        },
        // For multi select when we need it later on
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },

        _openPossibleCandidates: function _openPossibleCandidates(arrSelectedItems) {
            var iTimes = 0;
            var arrAvailability = [];

            // loop over selected items
            arrSelectedItems.forEach(function (objSelectedItem) {
                var shiftStartDate = momentHelper.stringFormat(objSelectedItem["start_date"], a24Constants.DATE_FORMAT_REST_FORMAT, a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY);

                var shiftEndDate = momentHelper.stringFormat(objSelectedItem["end_date"], a24Constants.DATE_FORMAT_REST_FORMAT, a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY);

                var objAvailability1 = {
                    bEncode: true,
                    mValue: "Available",
                    arSec: [{
                        sProp: "period",
                        bDelimited: true,
                        bEncode: true,
                        mValue: "Early|Late|Night"
                    }, {
                        sFilterValue: "dateFilterSpecificDate",
                        sProp: "range_start",
                        bTo: true,
                        bEncode: true,
                        mValue: shiftStartDate
                    }, {
                        sProp: "range_end",
                        bFrom: true,
                        bEncode: true,
                        mValue: shiftEndDate
                    }]
                };

                if (iTimes > 0) {
                    objAvailability1.bA = true;
                }

                arrAvailability.push(objAvailability1);

                var objAvailability2 = {
                    bEncode: true,
                    mValue: "Unknown",
                    arSec: [{
                        sProp: "period",
                        bDelimited: true,
                        bEncode: true,
                        mValue: "Early|Late|Night"
                    }, {
                        sFilterValue: "dateFilterSpecificDate",
                        sProp: "range_start",
                        bTo: true,
                        bEncode: true,
                        mValue: shiftStartDate
                    }, {
                        sProp: "range_end",
                        bFrom: true,
                        bEncode: true,
                        mValue: shiftEndDate
                    }],
                    bO: true
                };

                arrAvailability.push(objAvailability2);

                iTimes = iTimes + 1;
            });

            var objEmploymentHeld = [{
                bEncode: true,
                sFilterValue: arrSelectedItems[0].job_family_name,
                mValue: arrSelectedItems[0].job_family_id,
                arSec: [{
                    sProp: "job_title_id",
                    sFilterValue: arrSelectedItems[0].job_title_name,
                    bEncode: true,
                    mValue: arrSelectedItems[0].job_title_id
                }, {
                    sProp: "overall_duration",
                    bEncode: true,
                    bFrom: true,
                    mValue: 15552000,
                    iNumberOfMonths: 6
                }, {
                    sProp: "work_suggestion_status",
                    bEncode: true,
                    mValue: "active"
                }]
            }];

            var sAvailabilityUrl = btoa(JSON.stringify(arrAvailability));
            var sEmploymentHeldUrl = btoa(JSON.stringify(objEmploymentHeld));

            var objQuery = {
                availability___status: sAvailabilityUrl,
                employment_position_held___job_family_id: sEmploymentHeldUrl,
                pos_shift_order_id: arrSelectedItems[0].order_id,
                pos_shift_site_id: arrSelectedItems[0].site_id,
                vis_cols: "1,5,6,9,11,18,21"
            };

            if (arrSelectedItems[0].ward_id != null) {
                objQuery.pos_shift_ward_id = arrSelectedItems[0].ward_id;
            }

            var sUrl = this.get("_objNavigation").navigateWithinContext("agency-candidate-search", null, objQuery, true);

            this.openLink(sUrl, "_blank");
        },

        actions: {
            onFailedPopupCancel: function onFailedPopupCancel() {
                this.set("_bCancelPopupLoading", false);
                this.set("_bShowFailedPopup", false);
                this.set("_bShowCancelConfirmPopup", false);
                this.set("_bShowFailedPopup", false);
                if (this.get("_bReactStaffingPopupOpen")) {
                    var objPostbody = {
                        type: "refreshShiftDetails"
                    };
                    this.set("_objReactStaffingPopupPostData", objPostbody);
                }
                this._refreshShiftsList();
            },
            onCancelPopupClick: function onCancelPopupClick() {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bShowCancelConfirmPopup", true);
                }
            },
            onCancelPopupCancel: function onCancelPopupCancel() {
                this.set("_bShowCancelConfirmPopup", false);
            },
            onCancelPopupConfirm: function onCancelPopupConfirm() {
                this._popupCancelConfirm();
            },
            onCancelPopupClose: function onCancelPopupClose() {
                this._popupCancelClose();
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "order_reference") {
                    this._viewOrder(objRowData.order_id);
                } else if (sColumnName === "site_id") {
                    this._viewClientInNewWindow(objRowData.site_id, this.get("_objSSAgencyEnumsService.nodeLevel.SITE.backend"));
                } else if (sColumnName === "ward_name") {
                    if (!a24Core.isEmpty(objRowData.ward_id)) {
                        this._viewClientInNewWindow(objRowData.ward_id, this.get("_objSSAgencyEnumsService.nodeLevel.WARD.backend"));
                    }
                } else if (sColumnName === "candidate_full_name") {
                    if (!a24Core.isEmpty(objRowData.candidate_id)) {
                        this._viewCandidateInNewWindow(objRowData.candidate_id);
                    }
                } else if (sColumnName === "start_date") {
                    this.set("_bReactStaffingPopupOpen", true);
                    this.set("_objReactStaffingPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/shift/viewPopup",
                            data: {
                                shiftId: objRowData.shift_id,
                                orderId: objRowData.order_id
                            }
                        }
                    });
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                var arrShiftIds = [];
                for (var i = 0; i < arrTableData.length; i++) {
                    arrShiftIds.push(arrTableData[i].shift_id);
                }
                this._doEntityActionCalls(arrShiftIds);

                var arrConsultantRoles = this.get("_arrConsultantRoles");
                for (var l = 0; l < arrTableData.length; l++) {
                    if (!a24Core.isEmpty(arrTableData[l].client_consultants)) {
                        var _loop2 = function _loop2(_i) {
                            var arrData = arrTableData[l].client_consultants.filter(function (objEntry) {
                                return objEntry.consultant_role_id === arrConsultantRoles[_i]._id;
                            });
                            if (!a24Core.isEmpty(arrData)) {
                                var _sProp = "client_consultants_" + arrConsultantRoles[_i].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[_i]._id;
                                arrTableData[l][_sProp] = arrData;
                            }
                        };

                        for (var _i = 0; _i < arrConsultantRoles.length; _i++) {
                            _loop2(_i);
                        }
                    }
                }

                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall()
            /*arrTableData,
            objUrlConstantPart,
            objFilterParamObject,
            objTableParamObject,
            iPage,
            iPerPage,
            objSuccessCallback,
            objFailure,
            objProject*/
            {
                // need to add this to support projection when it's added
                // let arrConsultantColumns = this.get("_arrConsultantColumns");
                // if (!a24Core.isEmpty(arrConsultantColumns)) {
                //     objProject.consultants = {
                //         mValue: 1,
                //         bProject: true
                //     }
                //     for (let i = 0; i < arrConsultantColumns.length; i++) {
                //         if (!a24Core.isEmpty(objProject[arrConsultantColumns[i]])) {
                //             delete objProject[arrConsultantColumns[i]];
                //         }
                //     }
                // }

                if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                    this.get("onWillDoServiceCall").apply(undefined, arguments);
                }
            },
            onRecieveReactData: function onRecieveReactData(objData) {
                if (objData.type === "app_ready" || objData.type === "router_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refreshShiftsList();
                    if (this.get("_bReactStaffingPopupOpen")) {
                        var objPostbody = {
                            type: "refreshShiftDetails"
                        };
                        this.set("_objReactStaffingPopupPostData", objPostbody);
                    }
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            },
            onRecieveReactStaffingData: function onRecieveReactStaffingData(objData) {
                if (objData.type === "app_ready" || objData.type === "router_ready") {
                    this.set("_bReactStaffingPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refreshShiftsList();
                } else if (objData.type === "open_cancel_shift_popup") {
                    this._cancelShifts(objData.data.shifts);
                } else if (objData.type === "close") {
                    this.set("_bReactStaffingPopupOpen", false);
                }
            },
            addQueryParamsAction: function addQueryParamsAction(arrAddedParams) {
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrAddedParams);
                }
            }
        }
    });
});