define("a24-staffshift-subdomain-agency/components/page-agency-linked-client-search", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,

        _bPageLoading: true,

        _objGetSiteSuggestions: null,
        _objGetComplianceContract: null,
        _objGetOrgSuggestions: null,
        _objDatagridConfig: null,
        _objSelectedItem: null,
        _objSelectedRightSide: null,

        _objSiteTypesResponse: null,
        _objWardTypesResponse: null,
        _objConsulantRoleResponse: null,
        _objRoleEntityActionResponse: null,

        _arrSelectedItems: null,
        _arrConsultantRoles: null,
        _arrRoleEntityActionsSection: null,
        _arrBookingRequestPermissionedAction: null,
        _arrSingleLoadingActions: null,
        _arrSingleActions: null,
        _arrExtraActions: null,

        _objActivityTypeResponse: null,
        _bShowLoadFailedPopup: false,

        // strings, enums, constants
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),

        // services
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objMenuService: Ember.inject.service("triage-menu"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objFilterCustomWardService: Ember.inject.service("card-datagrid-custom-filter-ward"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),
        _objFilterCustomConsultantRoleService: Ember.inject.service("card-datagrid-custom-filter-consultant-role"),

        // Rest Services
        _objAgencySiteService: Ember.inject.service("rest-agency-site"),
        _objAgencyWardService: Ember.inject.service("rest-agency-site-ward"),
        _objAgencyOrgService: Ember.inject.service("rest-agency-organisation"),
        _objSiteTypesService: Ember.inject.service("rest-site-type"),
        _objWardTypesService: Ember.inject.service("rest-ward-type"),
        _objComplianceContractService: Ember.inject.service("rest-agency-compliance-contract"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        _objConsultantRoleService: Ember.inject.service("rest-agency-consultant-role"),
        _objUsersService: Ember.inject.service("rest-agency-users"),
        _objRestEntityActionSection: Ember.inject.service("rest-entity-action-section"),
        _objRestPermissionedActions: Ember.inject.service("rest-permissioned-actions"),
        _objRestActivityType: Ember.inject.service("rest-advanced-search-activity-type"),

        // react component
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),
        _sIFrameUrl: null,
        _bReactPopupLoading: true,
        _bReactPopupOpen: false,
        _objReactPopupPostData: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("clientSearch"));

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/agency-client");

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this.set("_objMenuService.bRightPanelInitClosed", true);
            this.set("_objMenuService.sRightPanelComponent", "right-panel-client-view");
            this.set("_objMenuService.mRightPanelComponentHideOverflow", true);
            this._observeSelectionChanged();

            this.doServiceCalls();
        },


        _handleServerErrors: function _handleServerErrors() {
            var _this = this;

            Ember.run.next(function () {
                _this.set("_bShowLoadFailedPopup", true);
            });
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_bPageLoading", true);
            var objQuery = {
                sortBy: { mValue: "sSiteTypeName" },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objPromiseSiteTypes = this.get("_objSiteTypesService").getSiteTypes(this, "_objSiteTypesResponse", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            objQuery.sortBy = { mValue: "sWardTypeName" };

            var objPromiseWardTypes = this.get("_objWardTypesService").getWardTypes(this, "_objWardTypesResponse", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objPromiseConsultantRole = this.get("_objConsultantRoleService").getConsultantRoles(this, "_objConsulantRoleResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objRoleEntityActionSectionPromise = this.get("_objRestEntityActionSection").getEntityActionsForSection(this, "_objRoleEntityActionResponse", "agency-client-consultant", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2.set("_arrRoleEntityActionsSection", []);
            }));

            var objBookingRequestPermissionedActionsPromise = this.get("_objRestPermissionedActions").getPermissionedActionsForSection(this, "_objGetPermissionedActions", "booking-request", function () {
                // do nothing since rsvp will handle it
            }, a24RestCallHelper.createJsonAjaxFailureObject(["401", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2.set("_arrBookingRequestPermissionedAction", []);
            }));

            var objActivityTypesQueryParams = {
                disabled: { mValue: false },
                name: { mValue: "asc", bSortBy: true },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 },
                subject_type: { mValue: this.get("_objSSCoreEnum.activitySubjectTypes.CLIENT.backend") }
            };

            var objPromiseActivityTypes = this.get("_objRestActivityType").getActivityTypes(this, "_objActivityTypeResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), objActivityTypesQueryParams);

            var objPromiseList = {
                _objRSVPSiteTypes: objPromiseSiteTypes,
                _objRSVPWardTypes: objPromiseWardTypes,
                _objRSVPConsulantRoles: objPromiseConsultantRole,
                _objRSVPConsultantRoleEntityAction: objRoleEntityActionSectionPromise,
                _objRSVPBookingRequestPermissionedAction: objBookingRequestPermissionedActionsPromise,
                _objRSVPActivityTypes: objPromiseActivityTypes
            };

            var arrRequiredResponses = ["_objRSVPSiteTypes", "_objRSVPWardTypes", "_objRSVPConsulantRoles", "_objRSVPActivityTypes"];

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {

                    var arrSiteTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPSiteTypes) && !a24Core.isEmpty(objResponses._objRSVPSiteTypes.objResponse)) {
                        arrSiteTypes = objResponses._objRSVPSiteTypes.objResponse;
                    }

                    var arrWardTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPWardTypes) && !a24Core.isEmpty(objResponses._objRSVPWardTypes.objResponse)) {
                        arrWardTypes = objResponses._objRSVPWardTypes.objResponse;
                    }

                    var arrConsultantRoles = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPConsulantRoles) && !a24Core.isEmpty(objResponses._objRSVPConsulantRoles.objResponse)) {
                        arrConsultantRoles = objResponses._objRSVPConsulantRoles.objResponse;
                    }

                    objThis.set("_arrConsultantRoles", arrConsultantRoles);

                    var arrActivityTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPActivityTypes.objResponse)) {
                        arrActivityTypes = objResponses._objRSVPActivityTypes.objResponse;
                    }

                    objThis._setupPageConfig(arrSiteTypes, arrWardTypes, arrConsultantRoles, arrActivityTypes);
                }
            }, {
                arrRequiredResponses: ["_objRSVPConsultantRoleEntityAction", "_objRSVPBookingRequestPermissionedAction"],
                funcOnComplete: function funcOnComplete(objResponses) {

                    var arrRoleEntityActionsSection = objResponses._objRSVPConsultantRoleEntityAction.objResponse;

                    if (a24Core.isEmpty(arrRoleEntityActionsSection)) {
                        arrRoleEntityActionsSection = [];
                    }

                    var arrBookingRequestPermissionedAction = objResponses._objRSVPBookingRequestPermissionedAction.objResponse;

                    if (a24Core.isEmpty(arrBookingRequestPermissionedAction)) {
                        arrBookingRequestPermissionedAction = [];
                    }

                    objThis.set("_arrRoleEntityActionsSection", arrRoleEntityActionsSection);

                    objThis.set("_arrBookingRequestPermissionedAction", arrBookingRequestPermissionedAction);

                    objThis._setDatagridActions();
                }
            }]);
        },

        _setDatagridActions: function _setDatagridActions() {
            var _this3 = this;

            var arrEntityActions = this.get("_arrRoleEntityActionsSection");
            var arrBookingRequestActions = this.get("_arrBookingRequestPermissionedAction");

            var sGroupAddSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("group_add");
            var sGroupRemoveSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("group_remove");
            var sPunchClockSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("punch_clock");

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewSite"),
                sIcon: "visibility", // "visibility", "business"
                executeCardAction: function executeCardAction() {
                    var sClientId = _this3.get("_objSelectedItem.site_id");
                    _this3._doClientView(sClientId, _this3.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    var sClientId = _this3.get("_objSelectedItem.site_id");
                    _this3._viewClientInNewWindow(sClientId, _this3.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("viewWard"),
                sIcon: "visibility", // "visibility", "other_houses"
                executeCardAction: function executeCardAction() {
                    var sClientId = _this3.get("_objSelectedItem.ward_id");
                    _this3._doClientView(sClientId, _this3.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    var sClientId = _this3.get("_objSelectedItem.ward_id");
                    _this3._viewClientInNewWindow(sClientId, _this3.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("viewOrg"),
                sIcon: "visibility", // "visibility", "business"
                executeCardAction: function executeCardAction() {
                    var sClientId = _this3.get("_objSelectedItem.organisation_id");
                    _this3._doClientView(sClientId, _this3.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    var sClientId = _this3.get("_objSelectedItem.organisation_id");
                    _this3._viewClientInNewWindow(sClientId, _this3.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("linkedPayrollContract"),
                sIcon: "grading",
                executeCardAction: function executeCardAction() {
                    _this3._doClientPayrollContractView(_this3.get("_objSelectedItem"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this3.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend") !== arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("bulkAssignConsultants"),
                sIconSvg: sGroupAddSvg,
                executeCardAction: function executeCardAction() {
                    _this3.set("_bReactPopupOpen", true);
                    _this3.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/assignConsultant",
                            data: {
                                clientIds: _this3._getClientsIdsFromSelected()
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("bulkUnassignConsultants"),
                sIconSvg: sGroupRemoveSvg,
                executeCardAction: function executeCardAction() {
                    _this3.set("_bReactPopupOpen", true);
                    _this3.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/deassignConsultant",
                            data: {
                                clientIds: _this3._getClientsIdsFromSelected()
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("assignComplianceContract"),
                sIcon: "assignment_turned_in",
                executeCardAction: function executeCardAction() {
                    var sClientName = _this3.get("_objSelectedItem.site_name");
                    if (!a24Core.isEmpty(_this3.get("_objSelectedItem.ward_name"))) {
                        sClientName += " - " + _this3.get("_objSelectedItem.ward_name");
                    }
                    _this3.set("_bReactPopupOpen", true);
                    _this3.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/linkedContracts/assignPopup",
                            data: {
                                client_name: sClientName,
                                organisation_id: _this3.get("_objSelectedItem.organisation_id"),
                                site_id: _this3.get("_objSelectedItem.site_id"),
                                ward_id: _this3.get("_objSelectedItem.ward_id")
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return (_this3.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level) && a24Core.isEmpty(arrSelectedItems[0].compliance_contract_link_id);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("removeComplianceContract"),
                sIcon: "assignment_late",
                executeCardAction: function executeCardAction() {
                    var sClientName = _this3.get("_objSelectedItem.site_name");
                    if (!a24Core.isEmpty(_this3.get("_objSelectedItem.ward_name"))) {
                        sClientName += " - " + _this3.get("_objSelectedItem.ward_name");
                    }
                    _this3.set("_bReactPopupOpen", true);
                    _this3.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/linkedContracts/deassignPopup",
                            data: {
                                client_name: sClientName,
                                contract_name: _this3.get("_objSelectedItem.compliance_contract_name"),
                                organisation_id: _this3.get("_objSelectedItem.organisation_id"),
                                contract_link_id: _this3.get("_objSelectedItem.compliance_contract_link_id")
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return (_this3.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level) && !a24Core.isEmpty(arrSelectedItems[0].compliance_contract_link_id);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("addBookingRequest"),
                sIconSvg: sPunchClockSvg,
                executeCardAction: function executeCardAction() {
                    var objSelectedItem = _this3.get("_arrSelectedItems")[0];
                    _this3.set("_bReactPopupOpen", true);
                    var bIsWard = _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === objSelectedItem.node_level;
                    var sClientName = "" + objSelectedItem.site_name + (bIsWard ? " - " + objSelectedItem.ward_name : "");
                    var objClientDetails = {
                        siteName: objSelectedItem.site_name,
                        orgName: objSelectedItem.organisation_name,
                        clientType: objSelectedItem.node_level,
                        wardName: bIsWard ? objSelectedItem.ward_name : undefined
                    };
                    _this3.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directBookingWizard",
                            data: {
                                subjectId: _this3._getClientsIdsFromSelected()[0],
                                subjectType: "client",
                                clientDetails: objClientDetails,
                                headerName: sClientName
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    var bAllowAction = false;
                    if (!a24Core.isEmpty(arrBookingRequestActions)) {
                        var objPermissionedAction = arrBookingRequestActions.find(function (objPermissionedActionSection) {
                            return objPermissionedActionSection.entity_type === "booking-request";
                        });
                        var arrAllowedActions = [];
                        if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                            objPermissionedAction.actions.forEach(function (objItem) {
                                if (objItem.allowed) {
                                    arrAllowedActions.push(objItem.action);
                                }
                            });
                        }
                        bAllowAction = arrAllowedActions.includes("create");
                    }
                    var bSiteWard = _this3.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level;

                    return bAllowAction && bSiteWard;
                }
            }];

            if (!a24Core.isEmpty(arrEntityActions)) {
                var objAddAction = {
                    sTitle: this.get("_objSSAgencyStrings").getString("assignConsultant"),
                    sIcon: "supervisor_account",
                    executeCardAction: function executeCardAction() {
                        var sClientId = _this3.get("_objSelectedItem.site_id");
                        if (_this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === _this3.get("_objSelectedItem.node_level")) {
                            sClientId = _this3.get("_objSelectedItem.ward_id");
                        }
                        _this3.set("_bReactPopupOpen", true);
                        _this3.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/client/consultantRolePopup",
                                data: {
                                    clientId: sClientId
                                }
                            }
                        });
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        if (!a24Core.isEmpty(arrEntityActions)) {
                            var objConsultantRoleEntityAction = arrEntityActions.find(function (objEntityActionSection) {
                                return objEntityActionSection.entity_type === "agency-client-consultant";
                            });
                            var objAllowAction = objConsultantRoleEntityAction.actions.find(function (objAction) {
                                return objAction.action === "assign_consultant" && objAction.allowed;
                            });
                            if (!a24Core.isEmpty(objAllowAction) && (_this3.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this3.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level)) {
                                return true;
                            }
                        }
                        return false;
                    }
                };

                // Insert at index 6 since the 2 view buttons for each node level is first then we want the assign
                arrSingleItemActions.splice(6, 0, objAddAction);
            }

            // Doing this here since the entity actions call could be done before the config is setup
            if (!a24Core.isEmpty(this.get("_objDatagridConfig.arrSingleItemSelectActions"))) {
                this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleItemActions);
            }
            this.set("_arrSingleActions", arrSingleItemActions);
        },

        _setupPageConfig: function _setupPageConfig(arrSiteTypes, arrWardTypes, arrConsultantRoles, arrActivityTypes) {
            var _this4 = this;

            var arrNodeLevels = this.get("_objSSAgencyEnums").getList("nodeLevel");
            var arrNodeLevelsDropdown = [];
            for (var k = 0; k < arrNodeLevels.length; k++) {
                arrNodeLevelsDropdown.push({
                    sTitle: arrNodeLevels[k].sLabel,
                    sValue: arrNodeLevels[k].sValue
                });
            }

            var arrPaymentTerms = this.get("_objSSAgencyEnums").getList("paymentTerms");
            var arrPaymentTermsDropdown = [];
            for (var n = 0; n < arrPaymentTerms.length; n++) {
                arrPaymentTermsDropdown.push({
                    sTitle: arrPaymentTerms[n].sLabel,
                    sValue: arrPaymentTerms[n].sValue
                });
            }

            var arrFinancialHold = this.get("_objSSAgencyEnums").getList("financialHold");
            var arrFinancialHoldDropdown = [];
            for (var o = 0; o < arrFinancialHold.length; o++) {
                arrFinancialHoldDropdown.push({
                    sTitle: arrFinancialHold[o].sLabel,
                    sValue: arrFinancialHold[o].sValue
                });
            }

            var arrBookingPreferences = this.get("_objSSAgencyEnums").getList("bookingPreferences");
            var arrBookingPreferencesDropdown = [];
            for (var a = 0; a < arrBookingPreferences.length; a++) {
                arrBookingPreferencesDropdown.push({
                    sTitle: arrBookingPreferences[a].sLabel,
                    sValue: arrBookingPreferences[a].sValue
                });
            }

            var arrComplianceContractStatus = this.get("_objSSAgencyEnums").getList("complianceContractStatus");
            var arrComplianceContractStatusDropdown = [];
            for (var y = 0; y < arrComplianceContractStatus.length; y++) {
                arrComplianceContractStatusDropdown.push({
                    sTitle: arrComplianceContractStatus[y].sLabel,
                    sValue: arrComplianceContractStatus[y].sValue
                });
            }

            var arrSiteTypesDropdown = [];
            var arrSiteTypesIds = [];
            for (var i = 0; i < arrSiteTypes.length; i++) {
                arrSiteTypesIds.push(arrSiteTypes[i]._id);
                arrSiteTypesDropdown.push({
                    sTitle: arrSiteTypes[i].name,
                    sValue: arrSiteTypes[i].name
                });
            }

            var arrWardTypesDropdown = [];
            var arrWardTypesIds = [];
            for (var j = 0; j < arrWardTypes.length; j++) {
                arrWardTypesIds.push(arrWardTypes[j]._id);
                arrWardTypesDropdown.push({
                    sTitle: arrWardTypes[j].name,
                    sValue: arrWardTypes[j].name
                });
            }

            var arrConsultantRolesDropdown = [];
            arrConsultantRolesDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var l = 0; l < arrConsultantRoles.length; l++) {
                arrConsultantRolesDropdown.push({
                    sTitle: arrConsultantRoles[l].name,
                    sValue: arrConsultantRoles[l]._id
                });
            }

            var arrActivityTypeDropdown = [];
            for (var m = 0; m < arrActivityTypes.length; m++) {
                arrActivityTypeDropdown.push({
                    sTitle: arrActivityTypes[m].name,
                    sValue: arrActivityTypes[m].code
                });
            }

            var arrDateFilterDataEnum = this.get("_objEnumEmberLibService").getList("dateFilterOptions");
            var objCustomOption = arrDateFilterDataEnum.pop();
            arrDateFilterDataEnum.push(this.get("_objEnumEmberLibService").getList("dateFilterOptionsBetween")[0]);
            arrDateFilterDataEnum.push(objCustomOption);
            arrDateFilterDataEnum.push(this.get("_objEnumEmberLibService").getList("dateFilterOptionsNotEmpty")[0]);

            var arrDateOptionsExtra = [];
            for (var _i = 0; _i < arrDateFilterDataEnum.length; _i++) {
                arrDateOptionsExtra.push({
                    sTitle: arrDateFilterDataEnum[_i].sLabel,
                    sValue: arrDateFilterDataEnum[_i].sValue
                });
            }

            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");
            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";

            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
            var objConstants = this.get("_objConstantsLib");
            arrQueryType.push({
                "sValue": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS,
                "sLabel": this.get("_objStringsService").getString("contains")
            });

            var arrQueryTypeIsEmpty = [{
                sLabel: this.get("_objStringsService").getString("is"),
                sValue: this.get("_objConstantsLib").CARD_DATAGRID_FILTER_QUERY_TYPE_IS
            }, {
                sLabel: this.get("_objStringsService").getString("isEmpty"),
                sValue: this.get("_objConstantsLib").CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY
            }];

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_name",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: true,
                iWidth: 20
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sOrganisationId",
                sFilterOptionsLabelKey: "sOrganisationName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.organisation_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.organisation_name + "\">" + objRowData.organisation_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sOrganisationName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sOrganisationName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objAgencyOrgService").getAgencyOrganisations(_this4, "_objGetOrgSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_name",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: true,
                iWidth: 20
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sSiteId",
                sFilterOptionsLabelKey: "sSiteName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.site_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.site_name + "\">" + objRowData.site_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sSiteName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        bAgencyLinked: {
                            mValue: true
                        },
                        sortBy: {
                            mValue: "sSiteName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objAgencySiteService").getLinkedClients(_this4, "_objGetSiteSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        var arrFormatted = [];
                        for (var _i2 = 0; _i2 < arrData.length; _i2++) {
                            var objDataItem = arrData[_i2];
                            var sLabel = objDataItem.sOrganisationName + ": " + objDataItem.sSiteName;
                            objDataItem.sSiteName = sLabel;

                            arrFormatted.push(objDataItem);
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrFormatted);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_name",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                bIsClickable: true,
                iWidth: 20
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_id",
                sFilterType: "custom",
                bText: true,
                bSilent: true,
                bFilterOr: true,
                bHideEmptyCheck: true,
                objFilterSiteService: this.get("_objAgencySiteService"),
                objFilterWardService: this.get("_objAgencyWardService"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-ward",
                objFilterCustomService: this.get("_objFilterCustomWardService")
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("nodeLevel"),
                sProperty: "node_level",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrNodeLevelsDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("clientReference"),
                sProperty: "client_reference",
                sFilterType: "text",
                bText: true,
                bIsClickable: false,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryTypeIsEmpty, true),
                iWidth: 20
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objSSAgencyStrings").getString("siteType"),
                sProperty: "site_type_name",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrSiteTypesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSAgencyStrings").getString("wardType"),
                sProperty: "ward_type_name",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrWardTypesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objStringsService").getString("addressLine1"),
                sProperty: "site_address_line_1",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objStringsService").getString("addressLine2"),
                sProperty: "site_address_line_2",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objStringsService").getLocalisedString("province", sCountryCode),
                sProperty: "site_province",
                sFilterType: "text",
                bFilterOr: true,
                bFilterAnd: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objStringsService").getString("cityTown"),
                sProperty: "site_city",
                sFilterType: "text",
                bFilterOr: true,
                bFilterAnd: true,
                bText: true,
                bIsClickable: false,
                iWidth: 15,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objStringsService").getLocalisedString("postCode", sCountryCode),
                sProperty: "site_post_code",
                sFilterType: "text",
                bFilterOr: true,
                bFilterAnd: true,
                bText: true,
                bIsClickable: false,
                iWidth: 15,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objSSAgencyStrings").getString("siteReferenceNumber"),
                sProperty: "site_reference_number",
                sFilterType: "text",
                bText: true,
                bIsClickable: false,
                iWidth: 15,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objSSAgencyStrings").getString("wardReferenceNumber"),
                sProperty: "ward_reference_number",
                sFilterType: "text",
                bText: true,
                bIsClickable: false,
                iWidth: 15,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objCandidateProfileStringsService").getString("distance"),
                sProperty: "site_physical_geo_location",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 20,
                bHideEmptyCheck: true,
                bClientSearch: true,
                sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-distance",
                objFilterCustomService: this.get("_objFilterCustomCandidateDistanceService")
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("contractName"),
                sProperty: "compliance_contract_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        name: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "name"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this4.get("_objComplianceContractService").getComplianceContracts(_this4, "_objGetComplianceContract", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objSSAgencyStrings").getString("contractName"),
                sProperty: "compliance_contract_name",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStrings").getString("contractStatus"),
                sProperty: "compliance_contract_status",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrComplianceContractStatusDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objCandidateProfileStringsService").getString("consultantRole"),
                sProperty: "client_consultants___consultant_role_id",
                bMapping: true,
                sFilterType: "custom",
                arrConsultantRoles: arrConsultantRolesDropdown,
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                sSecondaryProperty: "consultant_id",
                sSecondaryValueProperty: "_id",
                sFilterCustomComponentName: "card-datagrid-custom-filter-consultant-role",
                objFilterCustomService: this.get("_objFilterCustomConsultantRoleService"),
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this4.get("_objUsersService").suggestUsers(_this4, "_objUsersPromise", _this4.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 22,
                sTitle: this.get("_objSSAgencyStrings").getString("previousShiftDate"),
                sProperty: "previous_shift_at",
                sFilterType: "date",
                bDate: true,
                arrMappingData: arrDateOptionsExtra,
                bHideEmptyCheck: false,
                bStopColumnSort: true,
                iMinWidth: 140,
                iWidth: 1
            }, {
                iUniqueColNum: 23,
                sTitle: this.get("_objSSAgencyStrings").getString("nextShiftDate"),
                sProperty: "next_shift_at",
                sFilterType: "date",
                bDate: true,
                arrMappingData: arrDateOptionsExtra,
                bHideEmptyCheck: false,
                bStopColumnSort: true,
                iMinWidth: 140,
                iWidth: 1
            }, {
                iUniqueColNum: 24,
                sTitle: this.get("_objSSAgencyStrings").getString("previousBookingDate"),
                sProperty: "previous_booking_at",
                sFilterType: "date",
                bDate: true,
                arrMappingData: arrDateOptionsExtra,
                bHideEmptyCheck: false,
                bStopColumnSort: true,
                iMinWidth: 140,
                iWidth: 1
            }, {
                iUniqueColNum: 25,
                sTitle: this.get("_objSSAgencyStrings").getString("nextBookingDate"),
                sProperty: "next_booking_at",
                sFilterType: "date",
                bDate: true,
                arrMappingData: arrDateOptionsExtra,
                bHideEmptyCheck: false,
                bStopColumnSort: true,
                iMinWidth: 140,
                iWidth: 1
            }, {
                iUniqueColNum: 26,
                sTitle: this.get("_objSSAgencyStrings").getString("firstShiftDate"),
                sProperty: "first_shift_at",
                sFilterType: "date",
                bDate: true,
                arrMappingData: arrDateOptionsExtra,
                bHideEmptyCheck: false,
                bStopColumnSort: true,
                iMinWidth: 140,
                iWidth: 1
            }, {
                iUniqueColNum: 27,
                sTitle: this.get("_objSSAgencyStrings").getString("firstBookedDate"),
                sProperty: "first_booking_at",
                sFilterType: "date",
                bDate: true,
                arrMappingData: arrDateOptionsExtra,
                bHideEmptyCheck: false,
                bStopColumnSort: true,
                iMinWidth: 140,
                iWidth: 1
            }, {
                iUniqueColNum: 28,
                sTitle: this.get("_objCandidateProfileStringsService").getString("activities"),
                sProperty: "activities___activity_type",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrActivityTypeDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 29,
                sTitle: this.get("_objSSAgencyStrings").getString("paymentTerm"),
                sProperty: "payment_term",
                sFilterType: "dropdown",
                bMapping: true,
                bStopColumnSort: true,
                bFilterOr: true,
                bFilterAnd: true,
                arrMappingData: arrPaymentTermsDropdown,
                iWidth: 8
            }, {
                iUniqueColNum: 30,
                sTitle: this.get("_objSSAgencyStrings").getString("paymentTermInherited"),
                sProperty: "payment_term_inherited",
                sFilterType: "bool",
                bBool: true,
                bMapping: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8
            }, {
                iUniqueColNum: 31,
                sTitle: this.get("_objSSAgencyStrings").getString("financialHold"),
                sProperty: "financial_hold",
                sFilterType: "dropdown",
                bMapping: true,
                bStopColumnSort: true,
                bFilterOr: true,
                bFilterAnd: true,
                arrMappingData: arrFinancialHoldDropdown,
                iWidth: 8
            }, {
                iUniqueColNum: 32,
                sTitle: this.get("_objSSAgencyStrings").getString("financialHoldInherited"),
                sProperty: "financial_hold_inherited",
                sFilterType: "bool",
                bBool: true,
                bMapping: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8
            }, {
                iUniqueColNum: 33,
                sTitle: this.get("_objSSAgencyStrings").getString("bookingPreferences"),
                sProperty: "booking_preferences",
                sFilterType: "dropdown",
                bMapping: true,
                bSilent: true,
                bFilterOr: true,
                bFilterAnd: true,
                arrMappingData: arrBookingPreferencesDropdown,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(arrQuery) {
                    var arrQueryObject = Ember.copy(arrQuery, true);

                    for (var _i3 = 0; _i3 < arrQueryObject.length; _i3++) {
                        arrQueryObject[_i3].sProp = "booking_preferences__" + arrQueryObject[_i3].mValue;
                        arrQueryObject[_i3].mValue = true;
                        if (!a24Core.isEmpty(arrQueryObject[_i3].bDelimitedNot) && arrQueryObject[_i3].bDelimitedNot) {
                            delete arrQueryObject[_i3].bDelimitedNot;
                            arrQueryObject[_i3].mValue = false;
                        }
                    }

                    return arrQueryObject;
                },
                iWidth: 8
            }];

            if (!a24Core.isEmpty(arrConsultantRoles)) {
                var objQueryParams = this.get("objQueryParams");
                var objQueryParamsUpdate = {};
                var arrQueryParamsNew = [];
                var arrQueryParams = [];
                for (var c = 0; c < arrConsultantRoles.length; c++) {
                    var sProp = "consultant_" + arrConsultantRoles[c].name.toLowerCase().replace(/ /g, "-");
                    arrQueryParams.push(sProp);
                    objQueryParamsUpdate[sProp] = "";
                    objQueryParamsUpdate.queryParams = arrQueryParams;

                    // Because the backend can decide to remove/add any role at any time
                    // we wont know the exact number. So we use the id instead
                    arrQueryParamsNew.push({
                        iUniqueColNum: arrConsultantRoles[c]._id,
                        sTitle: arrConsultantRoles[c].name,
                        sProperty: sProp,
                        bCustom: true,
                        sFilterType: "text",
                        bStopFiltering: true,
                        bStopColumnSort: true,
                        iWidth: 15,
                        funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                            var sConsultants = null;
                            var arrData = objRowData.client_consultants.filter(function (objEntry) {
                                return objEntry.consultant_role_name === objHeader.sTitle;
                            });

                            if (!a24Core.isEmpty(arrData)) {
                                var arrConsultantsFound = arrData.map(function (objConsultant) {
                                    return objConsultant.consultant_name;
                                });

                                sConsultants = arrConsultantsFound.join(', ');
                            }

                            if (!a24Core.isEmpty(sConsultants)) {
                                return Ember.String.htmlSafe("<span title=\"" + sConsultants + "\">" + sConsultants + "</span>");
                            }
                            return "";
                        }

                    });

                    objQueryParamsUpdate = Ember.Object.create(objQueryParamsUpdate);
                }

                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrQueryParams);
                }
                arrDataGridHeaderConfig.push.apply(arrDataGridHeaderConfig, arrQueryParamsNew);

                this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(arrQueryParams, objQueryParamsUpdate, objQueryParams, false, true));
            }

            var sGroupAddSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("group_add");
            var sGroupRemoveSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("group_remove");

            var arrSingleActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewSite"),
                sIcon: "visibility", // "visibility", "business"
                executeCardAction: function executeCardAction() {
                    var sClientId = _this4.get("_objSelectedItem.site_id");
                    _this4._doClientView(sClientId, _this4.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this4.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    var sClientId = _this4.get("_objSelectedItem.site_id");
                    _this4._viewClientInNewWindow(sClientId, _this4.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this4.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("viewWard"),
                sIcon: "visibility", // "visibility", "other_houses"
                executeCardAction: function executeCardAction() {
                    var sClientId = _this4.get("_objSelectedItem.ward_id");
                    _this4._doClientView(sClientId, _this4.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this4.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    var sClientId = _this4.get("_objSelectedItem.ward_id");
                    _this4._viewClientInNewWindow(sClientId, _this4.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this4.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("viewOrg"),
                sIcon: "visibility", // "visibility", "business"
                executeCardAction: function executeCardAction() {
                    var sClientId = _this4.get("_objSelectedItem.organisation_id");
                    _this4._doClientView(sClientId, _this4.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this4.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    var sClientId = _this4.get("_objSelectedItem.organisation_id");
                    _this4._viewClientInNewWindow(sClientId, _this4.get("_objSelectedItem.node_level"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return _this4.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend") === arrSelectedItems[0].node_level;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("bulkAssignConsultants"),
                sIconSvg: sGroupAddSvg,
                executeCardAction: function executeCardAction() {
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/assignConsultant",
                            data: {
                                clientIds: _this4._getClientsIdsFromSelected()
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("bulkUnassignConsultants"),
                sIconSvg: sGroupRemoveSvg,
                executeCardAction: function executeCardAction() {
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/unassignConsultant",
                            data: {
                                clientIds: _this4._getClientsIdsFromSelected()
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("assignComplianceContract"),
                sIcon: "assignment_turned_in",
                executeCardAction: function executeCardAction() {
                    var sClientName = _this4.get("_objSelectedItem.site_name");
                    if (!a24Core.isEmpty(_this4.get("_objSelectedItem.ward_name"))) {
                        sClientName += " - " + _this4.get("_objSelectedItem.ward_name");
                    }
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/linkedContracts/assignPopup",
                            data: {
                                client_name: sClientName,
                                organisation_id: _this4.get("_objSelectedItem.organisation_id"),
                                site_id: _this4.get("_objSelectedItem.site_id"),
                                ward_id: _this4.get("_objSelectedItem.ward_id")
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return (_this4.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this4.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level) && a24Core.isEmpty(arrSelectedItems[0].compliance_contract_link_id);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("removeComplianceContract"),
                sIcon: "assignment_late",
                executeCardAction: function executeCardAction() {
                    var sClientName = _this4.get("_objSelectedItem.site_name");
                    if (!a24Core.isEmpty(_this4.get("_objSelectedItem.ward_name"))) {
                        sClientName += " - " + _this4.get("_objSelectedItem.ward_name");
                    }
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/linkedContracts/deassignPopup",
                            data: {
                                client_name: sClientName,
                                contract_name: _this4.get("_objSelectedItem.compliance_contract_name"),
                                organisation_id: _this4.get("_objSelectedItem.organisation_id"),
                                contract_link_id: _this4.get("_objSelectedItem.compliance_contract_link_id")
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return (_this4.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this4.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level) && !a24Core.isEmpty(arrSelectedItems[0].compliance_contract_link_id);
                }
            }, {
                bLoader: true
            }];

            if (a24Core.isEmpty(this.get("_arrRoleEntityActionsSection"))) {
                // Insert at index 6 since the 2 view buttons for each node level is first then we want the assign
                arrSingleActions.splice(6, 0, {
                    bLoader: true
                });
            }

            this.set("_arrSingleLoadingActions", arrSingleActions);

            var arrMultiActions = [{
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    _this4._viewAllClientsInNewWindow(_this4.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    var bShow = false;
                    if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10) {
                        $.each(arrSelectedItems, function (iKey, objClient) {
                            if (objClient.node_level === _this4.get("_objSSAgencyEnums.nodeLevel.SITE.backend") || objClient.node_level === _this4.get("_objSSAgencyEnums.nodeLevel.WARD.backend")) {
                                bShow = true;
                            }
                        });
                    }
                    return bShow;
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("bulkAssignConsultants"),
                sIconSvg: sGroupAddSvg,
                executeCardAction: function executeCardAction() {
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/assignConsultant",
                            data: {
                                clientIds: _this4._getClientsIdsFromSelected()
                            }
                        }
                    });
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("bulkUnassignConsultants"),
                sIconSvg: sGroupRemoveSvg,
                executeCardAction: function executeCardAction() {
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/deassignConsultant",
                            data: {
                                clientIds: _this4._getClientsIdsFromSelected()
                            }
                        }
                    });
                }
            }];

            // Doing this here since the entity actions call could be done before the config is setup and then the actions
            // is already set correctly
            if (!a24Core.isEmpty(this.get("_arrSingleActions"))) {
                arrSingleActions = this.get("_arrSingleActions");
            }

            var sDiversitySvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("diversity_1");

            var objBulkAction = {
                "sIconSvg": sDiversitySvg,
                "sIconClass": "a24-grey-text-darken-1",
                "sTitle": this.get("_objSSAgencyStrings").getString("bulkConsultantManagement"),
                executeCardAction: function executeCardAction() {
                    _this4.set("_bReactPopupOpen", true);
                    _this4.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/client/bulk/bulkConsultantManagement",
                            data: {}
                        }
                    });
                }
            };

            this.set("_arrExtraActions", [objBulkAction]);

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("clientSearch"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                bHasReloadButton: true,
                iResultCountLimit: 251,
                arrSingleItemSelectActions: arrSingleActions,
                arrMultiItemSelectActions: arrMultiActions,
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-search-clients",
                arrHeaderConfig: arrDataGridHeaderConfig,
                objPermanentQuery: {
                    linked: {
                        mValue: true
                    }
                },
                arrFilterSecondary_activities___activity_type: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("lastOccurredAt"),
                    sProperty: "last_activity_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("numberOfOccurrences"),
                    sProperty: "total",
                    bHideEmptyCheck: true,
                    sFilterType: "int"
                }]
            });

            this.set("_bPageLoading", false);
        },
        _getClientsIdsFromSelected: function _getClientsIdsFromSelected() {
            var arrSelectedItem = this.get("_arrSelectedItems");
            var arrSelectedClientIds = [];
            for (var i = 0; i < arrSelectedItem.length; i++) {
                if (this.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItem[i].node_level) {
                    arrSelectedClientIds.push(arrSelectedItem[i].site_id);
                } else if (this.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItem[i].node_level) {
                    arrSelectedClientIds.push(arrSelectedItem[i].ward_id);
                } else if (this.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend") === arrSelectedItem[i].node_level) {
                    arrSelectedClientIds.push(arrSelectedItem[i].organisation_id);
                }
            }

            return arrSelectedClientIds;
        },
        _doClientPayrollContractView: function _doClientPayrollContractView(objSelectedItem) {
            var sSiteId = "";
            var sWardId = "";
            var sOrgId = objSelectedItem.organisation_id;
            var sNodeLevel = objSelectedItem.node_level;
            if (sNodeLevel !== this.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend")) {
                sSiteId = objSelectedItem.site_id;
                if (sNodeLevel === this.get("_objSSAgencyEnums.nodeLevel.WARD.backend")) {
                    sWardId = objSelectedItem.ward_id;
                }
            }

            this.openLink(this.get("_objNavigation").navigateToGWTPage("contract-list;organisationId=" + sOrgId + ";siteId=" + sSiteId + (!a24Core.isEmpty(sWardId) ? ";wardId=" + sWardId : "") + ";cId=" + this.get("_objNavigation.sContextId"), true, true));
        },
        _doClientView: function _doClientView(sClientId, sType) {
            var _this5 = this;

            var sRoute = "";
            var objRouteParams = {};
            switch (sType) {
                case this.get("_objSSAgencyEnums.nodeLevel.SITE.backend"):
                    sRoute = "agency-client-site-view";
                    break;
                case this.get("_objSSAgencyEnums.nodeLevel.WARD.backend"):
                    sRoute = "agency-client-ward-view";
                    break;
                case this.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend"):
                    sRoute = "agency-client-org-view";
                    break;
                default:
                    break;
            }

            objRouteParams[sRoute] = [sClientId];

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this5)) {
                    return;
                }
                _this5.get("_objNavigation").navigateWithinContext(sRoute, objRouteParams);
            });
        },
        _viewAllClientsInNewWindow: function _viewAllClientsInNewWindow(arrSelectedItems) {
            var _this6 = this;

            $.each(arrSelectedItems, function (iKey, objClient) {
                var sClientId = '';
                switch (objClient.node_level) {
                    case _this6.get("_objSSAgencyEnums.nodeLevel.SITE.backend"):
                        sClientId = objClient.site_id;
                        break;
                    case _this6.get("_objSSAgencyEnums.nodeLevel.WARD.backend"):
                        sClientId = objClient.ward_id;
                        break;
                    case _this6.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend"):
                        sClientId = objClient.organisation_id;
                        break;
                    default:
                        break;
                }
                _this6._viewClientInNewWindow(sClientId, objClient.node_level);
            });
        },
        _viewClientInNewWindow: function _viewClientInNewWindow(sClientId, sType) {
            var sRoute = "";
            var objRouteParams = {};
            switch (sType) {
                case this.get("_objSSAgencyEnums.nodeLevel.SITE.backend"):
                    sRoute = "agency-client-site-view";
                    break;
                case this.get("_objSSAgencyEnums.nodeLevel.WARD.backend"):
                    sRoute = "agency-client-ward-view";
                    break;
                case this.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend"):
                    sRoute = "agency-client-org-view";
                    break;
                default:
                    break;
            }

            objRouteParams[sRoute] = [sClientId];
            var sLink = this.get("_objNavigation").navigateWithinContext(sRoute, objRouteParams, null, true);
            this.openLink(sLink);
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },
        _addRemoveSuccess: function _addRemoveSuccess() {
            var _this7 = this;

            this.set("_bReactPopupOpen", false);
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this7)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this7.set("_bPageLoading", false);
                });
            });
        },

        _observeSelectionChanged: Ember.observer("_objSelectedRightSide", function () {
            this.set("_objMenuService.mRightPanelComponentData", this.get("_objSelectedRightSide"));
        }),
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            var objRightSide = {};
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);

                if (this.get("_objSelectedRightSide.sOrgId") != arrSelectedItems[0].organisation_id) {
                    objRightSide.sOrgId = arrSelectedItems[0].organisation_id;
                }

                if (this.get("_objSelectedRightSide.sSiteId") != arrSelectedItems[0].site_id) {
                    objRightSide.sSiteId = arrSelectedItems[0].site_id;
                }

                if (this.get("_objSelectedRightSide.sWardId") != arrSelectedItems[0].ward_id) {
                    objRightSide.sWardId = arrSelectedItems[0].ward_id;
                }

                if (a24Core.isEmpty(this.get("_objSelectedRightSide"))) {
                    this.set("_objSelectedRightSide", objRightSide);
                }
            } else {
                this.set("_objSelectedItem", null);
                this.set("_objSelectedRightSide", null);
            }
            this.get("_objMenuService").funcRigthPanelOpen(!a24Core.isEmpty(this.get("_objSelectedRightSide")));

            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },

        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "organisation_name") {
                    if (a24Core.isEmpty(objRowData.site_id) && a24Core.isEmpty(objRowData.ward_id)) {
                        this.set("_objSelectedRightSide", {
                            sOrgId: objRowData.organisation_id
                        });
                    } else if (!a24Core.isEmpty(objRowData.site_id) && a24Core.isEmpty(objRowData.ward_id)) {
                        this.set("_objSelectedRightSide", {
                            sOrgId: objRowData.organisation_id,
                            sSiteId: objRowData.site_id
                        });
                    } else {
                        this.set("_objSelectedRightSide", {
                            sOrgId: objRowData.organisation_id,
                            sSiteId: objRowData.site_id,
                            sWardId: objRowData.ward_id
                        });
                    }
                } else if (sColumnName === "site_name") {
                    if (a24Core.isEmpty(objRowData.ward_id)) {
                        this.set("_objSelectedRightSide", {
                            sOrgId: objRowData.organisation_id,
                            sSiteId: objRowData.site_id
                        });
                    } else {
                        this._doClientView(objRowData.site_id, this.get("_objSSAgencyEnums.nodeLevel.SITE.backend"));
                    }
                } else if (sColumnName === "ward_name") {
                    if (!a24Core.isEmpty(objRowData.ward_id)) {
                        this.set("_objSelectedRightSide", {
                            sOrgId: objRowData.organisation_id,
                            sSiteId: objRowData.site_id,
                            sWardId: objRowData.ward_id
                        });
                    }
                }
            },

            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this.doServiceCalls();
            },

            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._addRemoveSuccess();
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                var arrConsultantRoles = this.get("_arrConsultantRoles");
                for (var l = 0; l < arrTableData.length; l++) {
                    // add columns for consultant roles
                    for (var i = 0; i < arrConsultantRoles.length; i++) {
                        var sProp = "consultant_" + arrConsultantRoles[i].name.toLowerCase().replace(/ /g, "-");
                        arrTableData[l][sProp] = true;
                    }
                }

                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall()
            /*arrTableData,
            objUrlConstantPart,
            objFilterParamObject,
            objTableParamObject,
            iPage,
            iPerPage,
            objSuccessCallback,
            objFailure,
            objProject*/
            {
                // need to add this to support projection when it's added
                // let arrConsultantColumns = this.get("_arrConsultantColumns");
                // if (!a24Core.isEmpty(arrConsultantColumns)) {
                //     objProject.consultants = {
                //         mValue: 1,
                //         bProject: true
                //     }
                //     for (let i = 0; i < arrConsultantColumns.length; i++) {
                //         if (!a24Core.isEmpty(objProject[arrConsultantColumns[i]])) {
                //             delete objProject[arrConsultantColumns[i]];
                //         }
                //     }
                // }

                if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                    this.get("onWillDoServiceCall")();
                }
            },
            addQueryParamsAction: function addQueryParamsAction(arrAddedParams) {
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrAddedParams);
                }
            }
        }
    });
});