define("a24-staffshift-subdomain-agency/routes/index/agency-candidate-search-view/references", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        queryParams: {
            referencepage: {
                refreshModel: true
            }
        },
        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function modelHook() {
            this.get('_objNavigationService');
            if ($(".main-container").hasClass("tab-react-iframe") && this.get("_objNavigationService.objRouteQueryParams.referencepage") !== "v2") {
                $(".main-container").removeClass("tab-react-iframe");
            }
            if (!$(".main-container").hasClass("tab-react-iframe") && this.get("_objNavigationService.objRouteQueryParams.referencepage") === "v2") {
                $(".main-container").addClass("tab-react-iframe");
            }
        },
        observeQueryParam: Ember.observer("_objNavigationService.objRouteQueryParams.referencepage", function () {
            if (this.get("_objNavigationService.objRouteQueryParams.referencepage") === "v2") {
                $(".main-container").addClass("tab-react-iframe");
            } else {
                $(".main-container").removeClass("tab-react-iframe");
            }
        }),

        activate: function activate() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.schedule("afterRender", function () {
                if (_this.get("_objNavigationService.objRouteQueryParams.referencepage") === "v2") {
                    $(".main-container").addClass("tab-react-iframe");
                } else {
                    $(".main-container").removeClass("tab-react-iframe");
                }
            });
        },
        deactivate: function deactivate() {
            $(".main-container").removeClass("tab-react-iframe");
            this._super.apply(this, arguments);
        }
    });
});